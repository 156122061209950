import serviceBase from "./ServiceBase";

const forgotService = {
  emailUser: data => serviceBase.post("/api/emailUser", data),
  forgotAuth: data => serviceBase.post("/api/forgotAuth", data),
  tokenExpiration: data => serviceBase.post("/api/tokenExpiration", data),
  forgotcheckUser: data => serviceBase.post("/api/forgotcheckUser", data)
};

export default forgotService;
