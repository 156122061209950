import React, { useEffect } from "react";

import { CheckCircleTwoTone } from "@ant-design/icons";
import { Button } from "antd";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

import AOS from "aos";

const Pricing = ({ theme }) => {
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <div className="container-fluid">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Jorden McDonald Website Pricing Services</title>
        <meta
          name="description"
          content="Jorden McDonald offers services for building websites that meet clients needs and is flexible for client requests and budget | Ask for a quote today on your specific website needs and budget!"
        />
        <link rel="canonical" href="https://jordenmcdonald.com/pricing" />
      </Helmet>
      <div className="pricingContainer">
        <div className="container">
          <h1 className="pt-5">Pricing Options</h1>
          <p
            className={theme === "light" ? "text-black" : "text-white"}
            style={{ fontSize: "20px" }}
          >
            Let's understand your business needs.
          </p>
          <div
            className="pricingOptions shadow-lg rounded"
            data-aos="fade-down"
            data-aos-once="true"
          >
            <div className="row">
              <div className="col-lg-4">
                <div className="listPrice p-3">
                  {/* <h2 style={{ fontSize: "18px", fontWeight: "bold" }}>
                    Basic
                  </h2> */}
                  <div className="price">
                    <p id="price">Basic</p>
                  </div>
                  <div className="checkList">
                    <ul>
                      <li>
                        <CheckCircleTwoTone twoToneColor="#52c41a" />
                        Custom design/developed 5 page website
                      </li>
                      <li>
                        <CheckCircleTwoTone twoToneColor="#52c41a" />
                        Font family selection
                      </li>
                      <li>
                        <CheckCircleTwoTone twoToneColor="#52c41a" />
                        Color theme selection
                      </li>
                      <li>
                        <CheckCircleTwoTone twoToneColor="#52c41a" />
                        Fully responsive desktop, mobile and tablets
                      </li>
                      <li>
                        <CheckCircleTwoTone twoToneColor="#52c41a" />
                        Hosting setup
                      </li>
                      <li>
                        <CheckCircleTwoTone twoToneColor="#52c41a" />
                        Domain name setup
                      </li>
                    </ul>
                  </div>
                  <div className="pricingButton">
                    <Link to="/contact">
                      <Button type="primary" size="large">
                        Let's Chat
                      </Button>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-lg-4" style={{ backgroundColor: "#089AED" }}>
                <div className="listPrice p-3" id="recommended">
                  {/* <h2
                    style={{
                      fontSize: "18px",
                      fontWeight: "bold",
                      color: "#fff",
                    }}
                  >
                    Professional
                  </h2> */}
                  <div className="price">
                    <p className="text-white" id="price">
                      Professional
                    </p>
                  </div>
                  <div className="checkList">
                    <ul>
                      <li className="text-white">
                        <CheckCircleTwoTone twoToneColor="#52c41a" />
                        All features from basic package
                      </li>
                      <li className="text-white">
                        <CheckCircleTwoTone twoToneColor="#52c41a" />
                        Multiple pages creation upon discussion
                      </li>
                      <li className="text-white">
                        <CheckCircleTwoTone twoToneColor="#52c41a" />
                        Multiple component integration
                      </li>
                      <li className="text-white">
                        <CheckCircleTwoTone twoToneColor="#52c41a" />
                        Custom HTML email template design
                      </li>
                      <li className="text-white">
                        <CheckCircleTwoTone twoToneColor="#52c41a" />
                        Back-end integration
                      </li>
                      <li className="text-white">
                        <CheckCircleTwoTone twoToneColor="#52c41a" />
                        HTTPS enabled
                      </li>
                    </ul>
                  </div>
                  <div className="pricingButton">
                    <Link to="/contact">
                      <Button size="large">Let's Chat</Button>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="listPrice p-3">
                  {/* <h2 style={{ fontSize: "18px", fontWeight: "bold" }}>
                    Enterprise
                  </h2> */}
                  <div className="price">
                    <p id="price">Enterprise</p>
                  </div>
                  <div className="checkList">
                    <ul>
                      <li>
                        <CheckCircleTwoTone twoToneColor="#52c41a" />
                        All features from basic/professional package
                      </li>
                      <li>
                        <CheckCircleTwoTone twoToneColor="#52c41a" />
                        Custom module creation/functionalities
                      </li>
                      <li>
                        <CheckCircleTwoTone twoToneColor="#52c41a" />
                        Database creation
                      </li>
                      <li>
                        <CheckCircleTwoTone twoToneColor="#52c41a" />
                        API integration
                      </li>
                      <li>
                        <CheckCircleTwoTone twoToneColor="#52c41a" />
                        Login/Register (authentication/authorization)
                      </li>
                      <li>
                        <CheckCircleTwoTone twoToneColor="#52c41a" />
                        Multiple website changes
                      </li>
                    </ul>
                  </div>
                  <div className="pricingButton">
                    <Link to="/contact">
                      <Button type="primary" size="large">
                        Let's Chat
                      </Button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <h1 className="pt-5">Website Support/Maintenance</h1>
          <p
            className={theme === "light" ? "text-black" : "text-white"}
            style={{ fontSize: "20px" }}
          >
            If you are in need of short or long term support of your website or
            application, this feature offers all of services rendered below{" "}
          </p>
          <div className="pricingOptions shadow-lg rounded">
            <div className="maintainence p-3 mb-5">
              <div className="row">
                <div className="col-lg-6">
                  <div className="price">
                    <p id="price">Website Maintenance/Support</p>
                  </div>
                  <div className="checkList">
                    <ul>
                      <li>
                        <CheckCircleTwoTone twoToneColor="#52c41a" />
                        Website/web application training and support
                      </li>
                      <li>
                        <CheckCircleTwoTone twoToneColor="#52c41a" />
                        Changing how the website looks
                      </li>
                      <li>
                        <CheckCircleTwoTone twoToneColor="#52c41a" />
                        Changing content
                      </li>
                      <li>
                        <CheckCircleTwoTone twoToneColor="#52c41a" />
                        Changing functionality
                      </li>
                      <li>
                        <CheckCircleTwoTone twoToneColor="#52c41a" />
                        Changing photos for web optimization high resolution
                      </li>
                      <li>
                        <CheckCircleTwoTone twoToneColor="#52c41a" />
                        Multiple website changes overall
                      </li>
                      <li>
                        <CheckCircleTwoTone twoToneColor="#52c41a" />
                        SEO and performance testing
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-lg-6 d-flex align-items-center">
                  <img
                    className="w-100"
                    src="/images/pricing-vector.svg"
                    alt="Offering flexible pricing for website maintainence"
                    width="524px"
                    height="340px"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Pricing;
