import React, { useState } from "react";

import ReactCardFlip from "react-card-flip";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";

import FrontCard from "./FrontCard";
import BackCard from "./BackCard";

import {
  CloudDownloadOutlined,
  RotateLeftOutlined,
  RotateRightOutlined,
  QrcodeOutlined,
} from "@ant-design/icons";

import AOS from "aos"; // Animate on scroll

const BusinessCard = ({ theme }) => {
  const [isFlipped, setIsFlipped] = useState(false);
  AOS.init();

  const generatePDF = () => {
    const input = document.getElementById("content");
    html2canvas(input, {
      logging: true,
      letterRendering: 1,
      useCORS: true,
    }).then((canvas) => {
      const imgWidth = 308;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      const imgData = canvas.toDataURL("images/logooooo-sm.png");
      const pdf = new jsPDF("landscape", "mm", "a4");
      pdf.addImage(imgData, "PNG", 0, 0, imgWidth, imgHeight);
      pdf.save("mypdf.pdf");
    });
  };

  return (
    <div
      className="about-video-container mt-3"
      data-aos="fade-down"
      data-aos-once="true"
    >
      <h1>Business Card</h1>

      <div className="container-fluid">
        <div id="content">
          <ReactCardFlip isFlipped={isFlipped} flipDirection="horizontal">
            <FrontCard theme={theme} />
            <BackCard theme={theme} />
          </ReactCardFlip>
        </div>
        {/* Business Actions */}
        <div
          className="business-actions d-flex justify-content-center mx-auto mt-3"
          style={{
            backgroundColor: theme === "light" ? "#fff" : "#141414",
            width: "50%",
          }}
        >
          {isFlipped ? (
            <RotateLeftOutlined
              data-tip="Flip business card faceup"
              style={{
                fontSize: "30px",
                color: theme === "light" ? "#000" : "#fff",
              }}
              className="p-4 data-tip-bottom"
              onClick={() => setIsFlipped(false)}
            />
          ) : (
            <RotateRightOutlined
              data-tip="Flip business card facedown"
              style={{
                fontSize: "30px",
                color: theme === "light" ? "#000" : "#fff",
              }}
              className="p-4 data-tip-bottom"
              onClick={() => setIsFlipped(true)}
            />
          )}

          <CloudDownloadOutlined
            data-tip="Download business card"
            style={{
              fontSize: "30px",
              color: theme === "light" ? "#000" : "#fff",
            }}
            className="p-4 data-tip-bottom"
            onClick={generatePDF}
          />
        </div>
      </div>
    </div>
  );
};

export default BusinessCard;
