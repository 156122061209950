import React from "react";

import { Switch } from "antd";

const DarkModeTheme = ({ theme, toggleTheme }) => {
  let mode;
  let checkedState;

  if (
    localStorage.getItem("theme") === null ||
    localStorage.getItem("theme") === "dark"
  ) {
    mode = "Dark Mode";
    checkedState = true;
  } else if (theme === "dark") {
    mode = "Dark Mode";
    checkedState = true;
  } else {
    mode = "Light Mode";
    checkedState = false;
  }

  return (
    <div className="switch pr-3">
      <label className="pr-3">{mode}</label>
      <Switch
        checkedChildren={<i className="far fa-moon" />}
        unCheckedChildren={<i className="far fa-sun" />}
        onClick={toggleTheme}
        checked={checkedState}
      />
    </div>
  );
};

export default DarkModeTheme;
