import React from "react";

import Skeleton from "react-loading-skeleton";

const ColorSchemeSkeleton = (props) => {
  const { render } = props;

  const renderTemplates = () => {
    switch (render) {
      case "color":
        return <Skeleton width={170} height={20} count={4} />;
      default:
        return null;
    }
  };

  return <React.Fragment>{renderTemplates()}</React.Fragment>;
};

export default ColorSchemeSkeleton;
