import React from "react";

const BackCard = ({ theme }) => {
  return (
    <div className="business-root">
      <div className="business-card-info m-auto d-flex justify-content-start">
        <p className="p-0 font-weight-bold" style={{ fontSize: "16px" }}>
          Business Card Back
        </p>
      </div>
      <div
        className="container border-top border-bottom"
        id="business-media"
        style={{
          padding: "65px",
          backgroundColor: theme === "light" ? "#fff" : "#141414",
        }}
      >
        <div className="business-card-container d-flex justify-content-center p-3">
          <div className="business-card-header" id="header-row">
            <div className="row">
              <div className="col-sm-12 col-md-12 col-lg-4">
                <div className="logo">
                  <img
                    src={
                      theme === "light"
                        ? "/images/logo-sm-blk.png"
                        : "/images/logo-sm-white.png"
                    }
                    className="img-fluid logo"
                    alt="Jorden McDonald Business Card Logo"
                    width="60px"
                    height="60px"
                  />
                </div>
              </div>
              <div className="col-sm-12 col-md-12 col-lg-8">
                <span
                  id="logo-text"
                  style={{
                    letterSpacing: "2px",
                    fontWeight: "600",
                    fontSize: "20px",
                  }}
                >
                  Jorden&nbsp;
                </span>
                <span
                  id="logo-text"
                  style={{
                    letterSpacing: "2px",
                    fontWeight: "600",
                    color: "#089AED",
                    fontSize: "20px",
                  }}
                >
                  McDonald
                </span>
                <span
                  id="logo-text d-block"
                  style={{
                    letterSpacing: "2px",
                    fontWeight: "600",
                    color: "#089AED",
                    fontSize: "14px",
                    display: "block",
                  }}
                >
                  Front-end Developer
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BackCard;
