import React from "react";

import Skeleton from "react-loading-skeleton";

const DisplayWebProjectsSkeleton = (props) => {
  const { render } = props;

  const renderTemplates = () => {
    switch (render) {
      case "webtopic":
        return <Skeleton width={170} height={20} count={1} />;
      case "live":
        return <Skeleton width={50} height={50} count={1} />;
      case "viewproject":
        return <Skeleton width={100} height={50} count={1} />;
      case "webimage":
        return <Skeleton width={100} height={50} count={1} />;
      default:
        return null;
    }
  };

  return <React.Fragment>{renderTemplates()}</React.Fragment>;
};

export default DisplayWebProjectsSkeleton;
