import React, { useEffect, useState, useRef, Fragment } from "react";
import { useParams, useHistory } from "react-router-dom";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { Alert, Spin } from "antd";

import invoiceDetails from "../../../services/UserProfile";

import Moment from "moment";

const InvoiceDetails = () => {
  const history = useHistory();
  let { invoicenumber, lastname } = useParams();
  const invoiceRef = useRef(null);
  const [isLoading, setIsLoading] = useState(true);
  const [invoiceData, setInvoiceData] = useState([]);

  const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    date.setMinutes(date.getMinutes() + date.getTimezoneOffset());
    return Moment(date).format("MM/DD/YYYY");
  };

  const getInvoiceDetails = async () => {
    try {
      const response = await invoiceDetails.selectInvoicesByID({
        invoiceNumber: invoicenumber,
        lastName: lastname,
      });

      setInvoiceData(response);

      // Check if the response array is empty, and redirect to "/" if it is
      if (response.length === 0) {
        history.push("/"); // Redirect to "/" route
      } else {
        // Check if any invoice has already been paid
        const hasPaidInvoice = response.some(
          (invoice) => invoice.invoice_paid === "Yes"
        );

        if (hasPaidInvoice) {
          history.push("/"); // Redirect to "/" route if any invoice is already paid
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false); // Set loading state to false when data fetching is complete
    }
  };

  const generatePDF = (
    invoiceNumber,
    invoiceBillPersonTo,
    invoiceFromDate,
    invoiceToDate
  ) => {
    // Clean up the dates using the formatDate function
    const cleanedFromDate = formatDate(invoiceFromDate);
    const cleanedToDate = formatDate(invoiceToDate);

    const filename = `JordenMcDonald_Invoice${invoiceNumber}_${cleanedFromDate}-${cleanedToDate}_${invoiceBillPersonTo}`;

    html2canvas(invoiceRef.current).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "in", "letter"); // "p" for portrait orientation, "letter" for letter-sized paper (8.5 x 11 inches)
      const imgWidth = 8.5; // inches
      const imgHeight = (canvas.height * imgWidth) / canvas.width; // maintain aspect ratio
      pdf.addImage(imgData, "PNG", 0, 0, imgWidth, imgHeight);
      //pdf.save("invoice.pdf");
      pdf.save(filename + ".pdf"); // Save PDF with the formatted filename
    });
  };

  // Add this function outside the component.
  const handleClick = async (invoiceNumber, description, price, invoiceId) => {
    const items = {
      invoiceNumber: invoiceNumber,
      invoiceDescription: description,
      amount: price,
      invoiceId: invoiceId,
    };

    const url =
      process.env.NODE_ENV === "production"
        ? `https://jordenmcdonald.com/api/create-checkout-session`
        : `http://localhost:5000/api/create-checkout-session`;
    // const testUrl = "http://localhost:5000/api/create-checkout-session";

    // console.log("client invoice id is..", items);
    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(items),
    })
      .then((res) => {
        if (res.ok) return res.json();
        return res.json().then((json) => Promise.reject(json));
      })
      .then(({ url }) => {
        window.location = url;
      })
      .catch((e) => {
        console.error(e.error);
      });
  };

  function generateURL(invoiceNumber, lastName) {
    var hostname = window.location.hostname;
    var protocol = window.location.protocol;

    // Check if the application is running locally
    if (hostname === "localhost" || hostname === "127.0.0.1") {
      hostname = "http://localhost";
    } else {
      // For live environment
      hostname = protocol + "//" + hostname;
    }

    // Construct the URL using the hostname, invoice number, and last name
    var url = hostname + "/invoice-details/" + invoiceNumber + "/" + lastName;

    return url;
  }

  useEffect(() => {
    // console.log("key... public", process.env.STRIPE_PUBLIC_KEY);
    // console.log("key... public", process.env.STRIPE_SECRET_KEY_TEST);
    // console.log("env... is", process.env.NODE_ENV);

    getInvoiceDetails();
  }, []);

  return (
    <div className="container">
      <div
        ref={invoiceRef}
        className="invoice-container p-5"
        style={{ backgroundColor: "#fff", borderRadius: "35px" }}
      >
        {isLoading ? ( // Render loading indicator if isLoading is true
          <div className="spinner d-flex justify-content-center">
            <Spin size="large" />
          </div>
        ) : (
          <div
            ref={invoiceRef}
            className="invoice-container p-5"
            style={{ backgroundColor: "#fff", borderRadius: "35px" }}
          >
            {/* Rest of your UI code */}
            {invoiceData.map((invoice) => (
              <div key={invoice.invoice_id}>
                <div className="row">
                  <div className="col-sm-6">
                    <h1 style={{ color: "#000" }}>
                      INVOICE # {invoice.invoice_number}
                    </h1>
                    <p style={{ fontSize: "16px" }}>
                      <strong>Date:</strong>{" "}
                      {formatDate(invoice.invoice_from_date)} -{" "}
                      {formatDate(invoice.invoice_to_date)}
                    </p>
                    <p style={{ fontSize: "16px" }}>
                      <strong>Invoice URL:</strong>{" "}
                      <a href="" target="_blank" rel="noopener noreferrer">
                        {generateURL(invoicenumber, lastname)}
                      </a>
                    </p>
                  </div>

                  <div className="col-sm-6 text-right">
                    {/* This is the button that will send the user to the stripe paid to pay it. */}
                    <button
                      type="button"
                      id="checkoutButton"
                      style={{
                        padding: "10px",
                        marginRight: "10px",
                        borderRadius: "20px",
                        border: "none",
                        backgroundColor: "#0094e9",
                        textAlign: "left",
                        fontSize: "20px",
                        fontWeight: "bold",
                        color: "#fff",
                      }}
                      onClick={() =>
                        handleClick(
                          invoice.invoice_number,
                          invoice.invoice_description,
                          invoice.invoice_total_cost,
                          invoice.invoice_id
                        )
                      }
                    >
                      <i
                        className="fa fa-cc-stripe fa-2x"
                        aria-hidden="true"
                        style={{
                          verticalAlign: "sub",
                          paddingRight: "10px",
                          color: "#fff",
                        }}
                      />
                      Pay Invoice
                    </button>
                    <button
                      type="button"
                      id="checkoutButton"
                      style={{
                        padding: "10px",

                        borderRadius: "20px",
                        border: "2px solid black",
                        backgroundColor: "none",
                        textAlign: "left",
                        fontSize: "20px",
                        fontWeight: "bold",
                        color: "#000",
                      }}
                      onClick={() =>
                        generatePDF(
                          invoice.invoice_number,
                          invoice.invoice_bill_person_to,
                          invoice.invoice_from_date,
                          invoice.invoice_to_date
                        )
                      }
                    >
                      <i
                        className="fa fa-file-pdf fa-2x"
                        aria-hidden="true"
                        style={{
                          verticalAlign: "sub",
                          paddingRight: "10px",
                          color: "red",
                        }}
                      />
                      View Invoice PDF
                    </button>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-6">
                    <h2>BILL TO:</h2>
                    <p>{invoice.invoice_bill_company_to}</p>
                    <p>{invoice.invoice_bill_address_to}</p>
                    <p>
                      {invoice.invoice_bill_city_to},{" "}
                      {invoice.invoice_bill_state_to}{" "}
                      {invoice.invoice_bill_zip_to}
                    </p>
                    <p>{invoice.invoice_bill_person_to}</p>
                    <p>{invoice.invoice_bill_email_to}</p>
                  </div>
                  <div className="col-sm-6">
                    <h2>SHIP TO:</h2>
                    <p>Jorden McDonald</p>
                    <p>1524 Maryclair Drive</p>
                    <p>Olivehurst California, 95961</p>
                    <p>hey@jordenmcdonald.com</p>
                  </div>
                </div>
                <hr />
                <div className="row">
                  <div className="col-sm-12">
                    <h1 style={{ color: "#000" }}>Invoice Details:</h1>
                    <table className="table table-bordered table-responsive">
                      <thead>
                        <tr>
                          <th>QUANTITY</th>
                          <th>Item</th>
                          <th>Type</th>
                          <th>Description</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>{invoice.invoice_item_quantity}</td>
                          <td>{invoice.invoice_item}</td>
                          <td>{invoice.invoice_type}</td>
                          <td>{invoice.invoice_description}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12">
                    <h1 style={{ color: "#000" }}>Time Sheet Calculation:</h1>

                    <table className="table table-bordered table-responsive">
                      <thead>
                        <tr>
                          <th>Total Hours:</th>
                          <th>Notes</th>
                          <th>Calculations:</th>
                          <th>Calculated Total:</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>{invoice.invoice_total_time}</td>
                          <td>{invoice.invoice_notes}</td>
                          <td>
                            {invoice.invoice_rate}/hourly rate *{" "}
                            {invoice.invoice_total_time}/hours
                          </td>
                          <td>${invoice.invoice_total_cost}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="row">
                  <Fragment>
                    <Alert
                      message="A service fee of 2.9% as well as taxes will be applied to the checkout for electronic payments"
                      type="info"
                      showIcon
                      className="ml-3 mb-3"
                    />
                  </Fragment>
                  <div className="col-sm-12">
                    <table className="table table-bordered table-responsive">
                      <thead>
                        <tr>
                          <th>Comments:</th>
                          <th>Subtotal:</th>
                          <th>Total:</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <ul>
                              <li>Total payment due in 30 days.</li>
                              <li>
                                If paying by check, please include invoice
                                number on your check.
                              </li>
                              <li>
                                If you have any questions about this invoice,
                                please contact Jorden McDonald 916-546-0735
                                hey@jordenmcdonald.com
                              </li>
                              <li>Thank you for your business!</li>
                            </ul>
                          </td>
                          <td style={{ fontSize: "30px", paddingTop: "50px" }}>
                            <strong>${invoice.invoice_total_cost}</strong>
                          </td>
                          <td
                            style={{
                              fontSize: "30px",
                              paddingTop: "50px",
                            }}
                          >
                            <strong style={{ textDecoration: "underline" }}>
                              ${invoice.invoice_total_cost}
                            </strong>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default InvoiceDetails;
