import React from "react";

import { Redirect } from "react-router-dom";
import dataTip from "data-tip";

import userprofileService from "../../../services/userProfile";

import { Row, Col } from "react-bootstrap";

import AdminNavigation from "../../common/AdminModule";
import AdminTemplate from "./AdminTemplate";
import AdminInvoices from "./AdminInvoices";

class UserProfilePage extends React.Component {
  constructor(props) {
    super(props);

    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.userState = this.userState.bind(this);

    this.state = {
      redirect: false,
      userId: "",
      roleId: "",
      firstName: "",
      lastName: "",
      email: "",
      userName: "",
      profilePic: "",
      fileObject: "",
      fileName: "",
      fileType: "",
      fileSize: "",
      filePayload: "",
      templateState: 0,
      errorMsg: false,
      errorMsg2: false,
      errorMsg3: false,
      isLoading: true,
    };
  }

  handleClose() {
    this.setState({
      show: false,
    });
  }

  handleShow() {
    this.setState({
      show: true,
    });
  }

  async userState() {
    // Check if user is logged in
    if (localStorage.getItem("userData") === null) {
      this.setState({ redirect: true });
    } else {
      const userObject = JSON.parse(localStorage.getItem("userData"));

      this.setState({
        userId: userObject.userID,
        roleId: userObject.role_id,
        firstName: userObject.firstName,
        lastName: userObject.lastName,
        userName: userObject.username,
        email: userObject.email,
      });
    }
  }

  async selectUser() {
    const selectData = {
      profilePic: this.state.profilePic,
      userName: this.state.userName,
    };

    const selectuserData = await userprofileService.selectUser(selectData);

    this.setState({
      profilePic:
        "https://s3-us-west-1.amazonaws.com/profileimages-jorden/" +
        selectuserData[0].profilePic,
    });
  }

  async onSubmit(e) {
    e.preventDefault();
    // file size bytes in mb
    var fileCheck = Math.floor(Math.log(this.state.fileSize) / Math.log(1024));
    var allowedFileTypes = ["image/jpeg", "image/jpg", "image/png"];
    //Check if a uploaded photo was taken.
    if (this.state.fileObject === "") {
      this.setState({
        errorMsg: true,
        errorMsg2: false,
        errorMsg3: false,
      });
    } else if (!allowedFileTypes.includes(this.state.fileType)) {
      // check file type
      this.setState({
        errorMsg: false,
        errorMsg2: true,
        errorMsg3: false,
      });
    } else if (fileCheck >= 2) {
      // check file size
      this.setState({
        errorMsg: false,
        errorMsg2: false,
        errorMsg3: true,
      });
    } else {
      this.setState({
        errorMsg: false,
        errorMsg2: false,
        errorMsg3: false,
      });
      // Passed all file checks, update the photo.
      const updateData = {
        userName: this.state.userName,
        fileType: this.state.fileType,
        fileName: this.state.fileName,
        fileSize: this.state.fileSize,
        filePayload: this.state.filePayload,
      };
      const updateprofileData = await userprofileService.updateprofileImage(
        updateData
      );
      location.href = "/userProfile";
    }
  }

  async componentDidMount() {
    await this.userState();
    await this.selectUser();
  }

  renderTemplate = (templateId) => {
    this.setState({
      templateState: templateId,
    });
  };

  render() {
    if (this.state.redirect) {
      return <Redirect to={"/"} />;
    }

    return (
      <div className="container-fluid" id="userfluidprofile">
        <AdminNavigation
          userId={this.state.userId}
          firstName={this.state.firstName}
          lastName={this.state.lastName}
          email={this.state.email}
          userName={this.state.userName}
          profilePic={this.state.profilePic}
          renderTemplate={this.renderTemplate}
          isLoading={this.state.isLoading}
        />

        <div className="container" id="userprofile">
          <Row
            display="flex"
            justify="center"
            className="justify-content-center"
          >
            <Col md={24}>
              <React.Fragment>
                {this.state.roleId === 3 && this.state.templateState === 3 ? (
                  <AdminTemplate userID={this.state.userId} />
                ) : null}
              </React.Fragment>
            </Col>
          </Row>
          <Row display="flex" justify="center">
            <Col md={24}>
              <React.Fragment>
                {this.state.roleId === 3 && this.state.templateState === 0 ? (
                  <AdminInvoices userID={this.state.userId} />
                ) : null}
              </React.Fragment>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default UserProfilePage;
