import React from "react";
import forgotService from "../../../services/ForgotPasswordService";
import Recaptcha from "react-google-recaptcha";
import { Form, Input, Button, Row, Col } from "antd";
import Base64 from "base-64";

class ForgotPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: false,
      captchaVerify: false,
      errorMsg: false,
      errorMsg2: false,
      successMsg: false,
    };
  }

  captchaVerify = (value) => {
    const capVerify = value;
    if (capVerify !== "") {
      this.setState({
        captchaVerify: true,
      });
    }
  };

  onSubmit(e) {
    e.preventDefault();

    this.props.form.validateFields(async (err, values) => {
      if (!err) {
        var data = {
          email: values.email,
          emailEncode: Base64.encode(values.email),
        };

        //Check if user is in the db.
        const forgotcheckuserData = await forgotService.forgotcheckUser(data);

        if (forgotcheckuserData.length === 0) {
          this.setState({
            errorMsg: true,
          });

          this.props.form.resetFields();
        } else if (this.state.captchaVerify === false) {
          this.setState({ errorMsg2: true });
        } else {
          this.setState({
            errorMsg: false,
            errorMsg2: false,
            successMsg: true,
          });

          this.props.form.resetFields();

          const forgotpasswordData = await forgotService.emailUser(data);
        }
      }
    });
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <div className="container">
        <div className="forgotpasswordContainer" style={{ marginTop: "15%" }}>
          {/* User does not exist */}
          {this.state.errorMsg === true ? (
            <span style={{ color: "red" }}>
              That email address does not exist in our system.
            </span>
          ) : null}
          {/* Recaptcha not filled out */}
          {this.state.errorMsg2 === true ? (
            <span style={{ color: "red" }}>
              Please verify you're not a robot.
            </span>
          ) : null}
          {/* Success. Send forgot password email */}
          {this.state.successMsg === true ? (
            <span style={{ color: "green" }}>
              Success. Please check your email to reset your password.
            </span>
          ) : null}
          <Form onSubmit={(e) => this.onSubmit(e)}>
            <Row type="flex" justify="center">
              <Col md={8}>
                <Form.Item>
                  {getFieldDecorator("email", {
                    rules: [
                      { type: "email", message: "That is not a valid email!" },
                      {
                        required: true,
                        message: "Please put in an email address",
                      },
                    ],
                  })(<Input placeholder="Email Address" />)}
                </Form.Item>
              </Col>
            </Row>
            <div className="recaptcha">
              <Row type="flex" justify="center">
                <Recaptcha
                  theme="dark"
                  style={{
                    marginTop: "20px",
                  }}
                  sitekey={process.env.RECAPTCHA_API_KEY}
                  onChange={this.captchaVerify}
                />
              </Row>
            </div>
            <Row type="flex" justify="center">
              <Button type="primary" id="loginButton" htmlType="submit">
                Submit
              </Button>
            </Row>
            <Row type="flex" justify="center">
              <Button
                type="link"
                onClick={() => this.props.history.push("login")}
              >
                Login
              </Button>
            </Row>
          </Form>
        </div>
      </div>
    );
  }
}

const ForgotWrapper = Form.create({ name: "forgot_form" })(ForgotPassword);

export default ForgotWrapper;
