import React, { useState, useEffect } from "react";
import {
  Table,
  Divider,
  Icon,
  Row,
  Col,
  Popconfirm,
  Modal,
  Input,
  Form,
} from "antd";
import Moment from "moment";
import userprofileService from "../../../services/userProfile";
import PdfService from "../../../services/PdfService";
import AppController from "../../../controllers/AppController";

const { TextArea } = Input;

const AdminInvoices = () => {
  const [invoiceData, setInvoiceData] = useState([]);
  const [showInvoice, setShowInvoice] = useState(false);
  const [viewinvoiceData, setViewInvoiceData] = useState({
    invoice_date: "",
    invoice_notes: "",
    invoice_paid: "",
    invoice_type: "",
    invoice_total_time: "",
    invoice_rate: "",
    invoice_item: "",
    invoice_description: "",
    invoice_item_quantity: "",
    invoice_bill_email_to: "",
    invoice_bill_person_to: "",
    invoice_bill_zip_to: "",
    invoice_bill_state_to: "",
    invoice_bill_address_to: "",
    invoice_bill_company_to: "",
    invoice_bill_city_to: "",
    invoice_to_date: "",
    invoice_from_date: "",
    invoice_number: "",
  });
  const [showPDF, setShowPDF] = useState(false);
  const [visible, setVisible] = useState(false);
  const [showpdfID, setShowpdfID] = useState("");

  const selectInvoices = async () => {
    const fetchInvoices = await userprofileService.selectInvoices();
    const newArr = fetchInvoices.map((v, index) => ({ ...v, key: index }));
    const filterData = newArr.filter((item) => item.invoice_status === 1);
    setInvoiceData(filterData);
  };

  useEffect(() => {
    selectInvoices();
  }, []);

  const deleteInvoice = async (id) => {
    await userprofileService.deleteInvoices({ invoiceData: id });
    await selectInvoices();
  };

  const downloadInvoice = async (recordID) => {
    setShowpdfID(recordID.invoice_id);
    setShowPDF(true);

    const data = {
      invoiceID: recordID.invoice_id || "---",
      userID: recordID.user_id || "---",
      invoiceNumber: recordID.invoice_number || "---",
      invoicefromDate: recordID.invoice_from_date
        ? formatDate(recordID.invoice_from_date)
        : "---",
      invoicetoDate: recordID.invoice_to_date
        ? formatDate(recordID.invoice_to_date)
        : "---",
      invoiceDate: recordID.invoice_date
        ? formatDate(recordID.invoice_date)
        : "---",
      invoicebillcompanyTo: recordID.invoice_bill_company_to || "---",
      invoicebilladdressTo: recordID.invoice_bill_address_to || "---",
      invoicebillcityTo: recordID.invoice_bill_city_to || "---",
      invoicebillstateTo: recordID.invoice_bill_state_to || "---",
      invoicebillzipcodeTo: recordID.invoice_bill_zip_to || "---",
      invoicebillpersonTo: recordID.invoice_bill_person_to || "---",
      invoicebillemailTo: recordID.invoice_bill_email_to || "---",
      invoiceitemQuantity: recordID.invoice_item_quantity || "---",
      invoiceDescription: recordID.invoice_description || "---",
      invoiceItem: recordID.invoice_item || "---",
      invoiceRate: recordID.invoice_rate || "---",
      invoicetotalTime: recordID.invoice_total_time || "---",
      invoicetotalcost: recordID.invoice_total_cost || "---",
      invoicefixedCost: recordID.invoice_fixed_rate,
      invoiceNotes: recordID.invoice_notes || "---",
      invoiceType: recordID.invoice_type,
    };

    const trimSpaces = recordID.invoice_bill_person_to.replace(/ +/g, "");
    const downloadName = `JordenMcDonald_Invoice${
      recordID.invoice_number
    }_${formatDate(recordID.invoice_from_date)}-${formatDate(
      recordID.invoice_to_date
    )}_${trimSpaces}.pdf`;

    await PdfService.invoiceTemplate(data);

    setTimeout(() => {
      setShowPDF(false);
      const link = document.createElement("a");
      link.href = AppController.downloadPDF() + downloadName;
      link.target = "_blank";
      link.download = downloadName;
      link.dispatchEvent(new MouseEvent("click"));
    }, 3000);
  };

  const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    date.setMinutes(date.getMinutes() + date.getTimezoneOffset());
    return Moment(date).format("YYYY-MM-DD");
  };

  const editInvoiceRecord = (e) => {
    const { name, value } = e.target;
    setViewInvoiceData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const formatTimestamp = () => {
    return Moment().format("YYYY-MM-DD HH:mm:ss");
  };

  const editInvoice = async (recordData) => {
    setViewInvoiceData(recordData);
    setShowInvoice(true);
    setVisible(true);
  };

  const viewInvoice = async (recordData) => {
    setViewInvoiceData(recordData);
    setShowInvoice(false);
    setVisible(true);
  };

  const handleOk = async () => {
    console.log("test is.222.", viewinvoiceData.invoice_modified_date);

    const formattedData = {
      ...viewinvoiceData,
      invoice_from_date: formatDate(viewinvoiceData.invoice_from_date),
      invoice_to_date: formatDate(viewinvoiceData.invoice_to_date),
      invoice_date: formatDate(viewinvoiceData.invoice_date),
      invoice_modified_date: formatTimestamp(),
    };

    await userprofileService.updateInvoices(formattedData);
    setVisible(false);
    await selectInvoices();
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const columns = [
    {
      title: "Invoice #",
      dataIndex: "invoice_number",
      key: "invoice_number",
      width: 100,
    },
    {
      title: "Invoice Bill Company To",
      dataIndex: "invoice_bill_company_to",
      key: "invoice_bill_company_to",
      width: 150,
    },
    {
      title: "Invoice Bill Person To",
      dataIndex: "invoice_bill_person_to",
      key: "invoice_bill_person_to",
      width: 150,
    },
    {
      title: "Invoice Bill Email To",
      dataIndex: "invoice_bill_email_to",
      key: "invoice_bill_email_to",
      width: 150,
    },
    {
      title: "Invoice Paid?",
      dataIndex: "invoice_paid",
      key: "invoice_paid",
      width: 150,
    },
    {
      title: "Action",
      key: "action",
      width: 150,
      render: (text, record) => (
        <span>
          {showPDF && record.invoice_id === showpdfID ? (
            <Icon type="loading" style={{ fontSize: 24 }} spin />
          ) : (
            <Icon
              title="Download Invoice"
              type="file-pdf"
              style={{ cursor: "pointer" }}
              onClick={() => downloadInvoice(record)}
            />
          )}
          <Divider type="vertical" />
          <Icon
            title="Edit Invoice"
            type="edit"
            style={{ cursor: "pointer" }}
            onClick={() => editInvoice(record)}
          />
          <Divider type="vertical" />
          <Icon
            title="View Invoice Details"
            type="eye"
            style={{ cursor: "pointer" }}
            onClick={() => viewInvoice(record)}
          />
          <Divider type="vertical" />
          <Popconfirm
            title="Sure to delete?"
            onConfirm={() => deleteInvoice(record.invoice_id)}
          >
            <Icon
              title="Delete Invoice"
              type="delete"
              style={{ cursor: "pointer", color: "red" }}
            />
          </Popconfirm>
        </span>
      ),
    },
  ];

  return (
    <div className="adminInvoices">
      <Table
        striped
        bordered
        hover
        variant="dark"
        columns={columns}
        dataSource={invoiceData}
        scroll={{ x: 1180 }}
      />
      <Modal
        title="Invoice Details"
        visible={visible}
        onOk={handleOk}
        onCancel={handleCancel}
        centered
        width="850px"
      >
        <React.Fragment>
          <Row gutter={16}>
            <Col md={12}>
              <Form.Item label="Invoice ID">
                <Input value={viewinvoiceData.invoice_id} disabled />
              </Form.Item>
            </Col>
            <Col md={12}>
              <Form.Item label="Invoice #">
                <Input
                  name="invoice_number"
                  onChange={editInvoiceRecord}
                  value={viewinvoiceData.invoice_number}
                  disabled={!showInvoice}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col md={12}>
              <Form.Item label="Invoice Start Date">
                <Input
                  name="invoice_from_date"
                  onChange={editInvoiceRecord}
                  value={formatDate(viewinvoiceData.invoice_from_date)}
                  disabled={!showInvoice}
                />
              </Form.Item>
            </Col>
            <Col md={12}>
              <Form.Item label="Invoice End Date">
                <Input
                  name="invoice_to_date"
                  onChange={editInvoiceRecord}
                  value={formatDate(viewinvoiceData.invoice_to_date)}
                  disabled={!showInvoice}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col md={8}>
              <Form.Item label="Invoice Billed Company">
                <Input
                  name="invoice_bill_company_to"
                  onChange={editInvoiceRecord}
                  value={viewinvoiceData.invoice_bill_company_to}
                  disabled={!showInvoice}
                />
              </Form.Item>
            </Col>
            <Col md={8}>
              <Form.Item label="Invoice Billed Address">
                <Input
                  name="invoice_bill_address_to"
                  onChange={editInvoiceRecord}
                  value={viewinvoiceData.invoice_bill_address_to}
                  disabled={!showInvoice}
                />
              </Form.Item>
            </Col>
            <Col md={8}>
              <Form.Item label="Invoice Billed City">
                <Input
                  name="invoice_bill_city_to"
                  onChange={editInvoiceRecord}
                  value={viewinvoiceData.invoice_bill_city_to}
                  disabled={!showInvoice}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col md={8}>
              <Form.Item label="Invoice Billed State">
                <Input
                  name="invoice_bill_state_to"
                  onChange={editInvoiceRecord}
                  value={viewinvoiceData.invoice_bill_state_to}
                  disabled={!showInvoice}
                />
              </Form.Item>
            </Col>
            <Col md={8}>
              <Form.Item label="Invoice Billed Zip Code">
                <Input
                  name="invoice_bill_zip_to"
                  onChange={editInvoiceRecord}
                  value={viewinvoiceData.invoice_bill_zip_to}
                  disabled={!showInvoice}
                />
              </Form.Item>
            </Col>
            <Col md={8}>
              <Form.Item label="Invoice Billed Person">
                <Input
                  name="invoice_bill_person_to"
                  onChange={editInvoiceRecord}
                  value={viewinvoiceData.invoice_bill_person_to}
                  disabled={!showInvoice}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col md={8}>
              <Form.Item label="Invoice Billed Email">
                <Input
                  name="invoice_bill_email_to"
                  onChange={editInvoiceRecord}
                  value={viewinvoiceData.invoice_bill_email_to}
                  disabled={!showInvoice}
                />
              </Form.Item>
            </Col>
            <Col md={8}>
              <Form.Item label="Invoice Item Quantity">
                <Input
                  name="invoice_item_quantity"
                  onChange={editInvoiceRecord}
                  value={viewinvoiceData.invoice_item_quantity}
                  disabled={!showInvoice}
                />
              </Form.Item>
            </Col>
            <Col md={8}>
              <Form.Item label="Invoice Description">
                <TextArea
                  rows={4}
                  name="invoice_description"
                  onChange={editInvoiceRecord}
                  value={viewinvoiceData.invoice_description}
                  disabled={!showInvoice}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col md={8}>
              <Form.Item label="Invoice Item">
                <Input
                  name="invoice_item"
                  onChange={editInvoiceRecord}
                  value={viewinvoiceData.invoice_item}
                  disabled={!showInvoice}
                />
              </Form.Item>
            </Col>
            <Col md={8}>
              <Form.Item label="Invoice Rate">
                <Input
                  name="invoice_rate"
                  onChange={editInvoiceRecord}
                  value={"$" + viewinvoiceData.invoice_rate}
                  disabled={!showInvoice}
                />
              </Form.Item>
            </Col>
            <Col md={8}>
              <Form.Item label="Invoice Total Time Spent">
                <Input
                  name="invoice_total_time"
                  onChange={editInvoiceRecord}
                  value={viewinvoiceData.invoice_total_time}
                  disabled={!showInvoice}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col md={8}>
              <Form.Item label="Invoice Type">
                <Input
                  name="invoice_type"
                  onChange={editInvoiceRecord}
                  value={viewinvoiceData.invoice_type}
                  disabled={!showInvoice}
                />
              </Form.Item>
            </Col>
            <Col md={8}>
              <Form.Item label="Invoice Paid">
                <Input
                  name="invoice_paid"
                  onChange={editInvoiceRecord}
                  value={viewinvoiceData.invoice_paid}
                  disabled={!showInvoice}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col md={8}>
              <Form.Item label="Invoice Total Cost">
                <Input value={viewinvoiceData.invoice_total_cost} disabled />
              </Form.Item>
            </Col>
            <Col md={8}>
              <Form.Item label="Invoice Notes">
                <TextArea
                  rows={4}
                  name="invoice_notes"
                  onChange={editInvoiceRecord}
                  value={viewinvoiceData.invoice_notes}
                  disabled={!showInvoice}
                />
              </Form.Item>
            </Col>
            <Col md={8}>
              <Form.Item label="Invoice Date Created">
                <Input
                  name="invoice_date"
                  onChange={editInvoiceRecord}
                  value={formatDate(viewinvoiceData.invoice_date)}
                  disabled={!showInvoice}
                />
              </Form.Item>
            </Col>
          </Row>
        </React.Fragment>
      </Modal>
    </div>
  );
};

export default AdminInvoices;
