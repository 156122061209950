import React from "react";

import Skeleton from "react-loading-skeleton";

const WebProjectsSkeleton = (props) => {
  const { render } = props;

  const renderTemplates = () => {
    switch (render) {
      case "webheader":
        return <Skeleton width={170} height={20} count={1} />;
      case "weburl":
        return <Skeleton width={300} height={20} count={1} />;
      case "webprocess":
        return <Skeleton width={"100%"} height={20} count={8} />;
      case "webimage":
        return <Skeleton width={100} height={50} count={1} />;
      default:
        return null;
    }
  };

  return <React.Fragment>{renderTemplates()}</React.Fragment>;
};

export default WebProjectsSkeleton;
