import React, { Component } from "react";
import { Modal, Navbar, Nav, Image } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { Link } from "react-router-dom";
import {
  HomeOutlined,
  ContactsFilled,
  UserOutlined,
  DollarCircleOutlined,
} from "@ant-design/icons";

import { Popover, Divider } from "antd";

import LoginPage from "../components/LoginPage/LoginPage";
import RegisterPage from "../components/LoginPage/RegisterPage";
import ForgotPasswordPage from "../components/LoginPage/ForgotPasswordPage";
import DarkModeTheme from "./DarkModeTheme";

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showLogin: true,
      showRegister: false,
      showforgotPassword: false,
      isLoggedin: false,
      profilePic: "",
      // Mobile
      menuVisible: true,
      visible: false,
      // Recaptcha
      captchaVerify: false,
      showscrollTop: false,
    };
    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.Logout = this.Logout.bind(this);

    this.showLogin = this.showLogin.bind(this);
    this.showRegister = this.showRegister.bind(this);
    this.showforgotPassword = this.showforgotPassword.bind(this);
  }

  hide = () => {
    this.setState({
      visible: false,
    });
  };

  handleVisibleChange = (visible) => {
    this.setState({ visible });
  };

  captchaVerify = (value) => {
    const capVerify = value;
    if (capVerify !== value) {
      this.setState({ captchaVerify: true });
    }

    // console.log(capVerify);
    // console.log(this.state);
  };

  handleClose() {
    this.setState({
      show: false,
      showLogin: true,
      showRegister: false,
      showforgotPassword: false,
    });
  }

  scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  handleShow() {
    this.setState({ show: true });
  }

  showRegister() {
    this.setState({
      showLogin: false,
      showRegister: true,
      showforgotPassword: false,
    });
  }

  showLogin() {
    this.setState({
      showLogin: true,
      showRegister: false,
      showforgotPassword: false,
    });
  }

  showforgotPassword() {
    this.setState({
      showLogin: false,
      showRegister: false,
      showforgotPassword: true,
    });
  }

  // Log user out
  Logout() {
    localStorage.clear();
    location.href = "/";
  }

  toggleVisibility = () => {
    if (window.pageYOffset > 300) {
      this.setState({
        showscrollTop: true,
      });
    } else {
      this.setState({
        showscrollTop: false,
      });
    }
  };

  componentDidMount() {
    window.addEventListener("scroll", this.toggleVisibility);

    // Check if user is logged in
    if (localStorage.getItem("userData") !== null) {
      const userData = JSON.parse(localStorage.getItem("userData"));
      //console.log(userData);
      this.setState({
        isLoggedin: true,
        profilePic: userData.profilePic,
        userId: userData.userID,
        username: userData.username,
      });
    }

    //console.log(this.state.captchaVerify);
  }

  componentWillUnmount() {
    // Have to unmount component that adds the event listener or memory leak will happen. This fixes it.
    window.removeEventListener("scroll", this.toggleVisibility);
  }

  render() {
    let showForm = null;
    let formHeader = null;
    let formState = [
      this.state.showLogin,
      this.state.showRegister,
      this.state.showforgotPassword,
    ];
    const { theme, toggleTheme } = this.props;

    const content = (
      <React.Fragment>
        <Link
          to={"/userProfile/" + this.state.userId + "/" + this.state.username}
          style={{ color: "#000" }}
        >
          Profile
        </Link>

        <Divider style={{ margin: "10px 0" }} />
        <Link to="/" onClick={this.Logout} style={{ color: "#000" }}>
          Logout
        </Link>
      </React.Fragment>
    );

    //console.log(this.state);

    if (formState[0] === true) {
      showForm = (
        <LoginPage
          showRegister={this.showRegister}
          showforgotPassword={this.showforgotPassword}
          userId={this.state.userId}
          username={this.state.username}
          verifyCaptcha={this.state.captchaVerify}
        />
      );
      formHeader = "LOGIN";
    } else if (formState[1] === true) {
      showForm = (
        <RegisterPage
          verifyCaptcha={this.state.captchaVerify}
          showLogin={this.showLogin}
        />
      );
      formHeader = "REGISTER";
    } else if (formState[2] === true) {
      showForm = (
        <ForgotPasswordPage
          verifyCaptcha={this.state.captchaVerify}
          showLogin={this.showLogin}
        />
      );
      formHeader = "FORGOT PASSWORD";
    }

    return (
      <header>
        <div className="container">
          <Navbar collapseOnSelect expand="lg" bg={theme} variant={theme}>
            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav className="m-auto">
                <LinkContainer to="/">
                  <Nav.Link>
                    <HomeOutlined style={{ fontSize: "20px" }} />
                    <div className="test">PROJECTS</div>
                  </Nav.Link>
                </LinkContainer>
                <LinkContainer to="/pricing">
                  <Nav.Link>
                    <DollarCircleOutlined style={{ fontSize: "20px" }} />
                    PRICING
                  </Nav.Link>
                </LinkContainer>

                <LinkContainer to="/about">
                  <Nav.Link>
                    <UserOutlined style={{ fontSize: "20px" }} />
                    ABOUT
                  </Nav.Link>
                </LinkContainer>
                <LinkContainer to="/contact">
                  <Nav.Link>
                    <ContactsFilled style={{ fontSize: "20px" }} />
                    CONTACT
                  </Nav.Link>
                </LinkContainer>
              </Nav>
              {this.state.isLoggedin !== false ? (
                <Popover
                  content={content}
                  title="User Settings"
                  trigger="hover"
                  visible={this.state.visible}
                  placement="bottom"
                  onVisibleChange={this.handleVisibleChange}
                >
                  <Image
                    src={
                      "https://s3-us-west-1.amazonaws.com/profileimages-jorden/" +
                      this.state.profilePic
                    }
                    alt={this.state.profilePic + " avatar"}
                    roundedCircle
                    style={{ width: "50px", height: "50px", cursor: "pointer" }}
                  ></Image>
                </Popover>
              ) : (
                ""
              )}
              <DarkModeTheme theme={theme} toggleTheme={toggleTheme} />
            </Navbar.Collapse>
            <Modal
              show={this.state.show}
              onHide={this.handleClose}
              //Prevents modal from closing on clicking outside modal
              backdrop="static"
            >
              <Modal.Header closeButton>
                <Modal.Title>{formHeader}</Modal.Title>
              </Modal.Header>
              <Modal.Body>{showForm}</Modal.Body>
            </Modal>
          </Navbar>
          {/* Scroll to Top */}
          {this.state.showscrollTop ? (
            <i
              className="fas fa-arrow-up"
              id="scrolltopArrow"
              title="Scroll to top"
              onClick={() => this.scrollToTop()}
            ></i>
          ) : null}
        </div>
      </header>
    );
  }
}

export default Header;
