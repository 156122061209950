import React, { useState } from "react";
import { Link } from "react-router-dom";
import Moment from "moment";

import AppController from "../../../controllers/AppController";

import BlogSectionSkeleton from "../Skeletons/BlogSectionSkeleton";

import { Pagination } from "antd";

const BlogSection = (props) => {
  const { blogData } = props;
  const [current, setCurrent] = useState(1);

  const onChange = (page) => {
    setCurrent(page);
  };

  return (
    <div className="blogsection-container">
      {blogData
        ? blogData.map((rows, index) => (
            <div className="blog-container" key={index}>
              {rows.blog_topic ? (
                <Link
                  to={`/blog/${rows.blog_id}/${AppController.friendlyUrl(
                    rows.blog_topic
                  )}`}
                >
                  <h2>{rows.blog_topic}</h2>
                </Link>
              ) : (
                <BlogSectionSkeleton render={"blogheader"} />
              )}
              {rows.created_date && rows.blog_content ? (
                <div className="section-divider d-flex justify-content-between">
                  <p
                    className="section-paragraph"
                    style={{ width: "250px", wordBreak: "break-word" }}
                  >
                    {rows.blog_content.length > 50
                      ? rows.blog_content.substr(0, 50) + " ..."
                      : rows.blog_content}
                  </p>
                  <p className="section-date">
                    {Moment(rows.created_date).format("MM/DD/YYYY")}
                  </p>
                </div>
              ) : (
                <BlogSectionSkeleton render={"blogcontent"} />
              )}
            </div>
          ))
        : null}
      {/* <div className="pagination-container">
        <Pagination current={current} onChange={onChange} total={50} />
      </div> */}
    </div>
  );
};

export default BlogSection;
