import React from "react";
import "antd/dist/antd.css";
import { Menu, Icon, Row, Avatar } from "antd";

const { SubMenu } = Menu;

class AdminNavigation extends React.Component {
  // submenu keys of first level
  rootSubmenuKeys = ["sub1", "sub2", "sub3"];
  constructor(props) {
    super(props);
    this.state = {
      openKeys: ["sub1"],
      renderTemplate: 0,
    };
  }

  onOpenChange = (openKeys) => {
    const latestOpenKey = openKeys.find(
      (key) => this.state.openKeys.indexOf(key) === -1
    );
    if (this.rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      this.setState({ openKeys });
    } else {
      this.setState({
        openKeys: latestOpenKey ? [latestOpenKey] : [],
      });
    }
  };

  renderTemplates = (e, id) => {
    let templateId = id;
    // Pass id back from child to parent
    this.props.renderTemplate(templateId);
  };

  // componentDidMount = () => {
  //   console.log(this.props);
  // };

  render() {
    return (
      <div className="userSettings">
        <div className="position-fixed" id="userprofile">
          <Menu
            theme="dark"
            mode="inline"
            openKeys={this.state.openKeys}
            onOpenChange={this.onOpenChange}
            style={{ width: 350, height: "100vh" }}
          >
            {/* <Menu.Item key="admin1">
              <a onClick={(e) => this.renderTemplates(e, 3)}>
                <Icon type="user" />
                Update Profile
              </a>
            </Menu.Item>
            <Menu.Item key="admin2">
              <a onClick={(e) => this.renderTemplates(e, 3)}>
                <Icon type="rollback" />
                Reset Password
              </a>
            </Menu.Item>
            <Menu.Item key="admin3">
              <a onClick={(e) => this.renderTemplates(e, 3)}>
                <Icon type="lock" />
                Enable 2-Factor
              </a>
            </Menu.Item> */}
            <Menu.Item key="admin4">
              <a onClick={(e) => this.renderTemplates(e, 3)}>
                <Icon type="file-text" />
                Create Invoice
              </a>
            </Menu.Item>
            <Menu.Item key="admin5">
              <a onClick={(e) => this.renderTemplates(e, 0)}>
                <Icon type="dollar" />
                Show Invoices
              </a>
            </Menu.Item>
          </Menu>
        </div>
      </div>
    );
  }
}

export default AdminNavigation;
