import React, { useState, useEffect } from "react";
import { LinkContainer } from "react-router-bootstrap";
import { Nav } from "react-bootstrap";
import DarkModeTheme from "./DarkModeTheme";
import {
  HomeOutlined,
  ContactsFilled,
  UserOutlined,
  DollarCircleOutlined,
  MenuOutlined,
  CloseOutlined,
} from "@ant-design/icons";

const SideBar = ({ theme, toggleTheme, history }) => {
  const [sidebarOpen, setSidebarOpen] = useState(false);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (sidebarOpen && !event.target.closest(".sideBarContainer")) {
        setSidebarOpen(false);
      }
    };

    document.addEventListener("click", handleOutsideClick);

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [sidebarOpen]);

  useEffect(() => {
    const unlisten = history.listen(() => {
      // Close the sidebar on route change
      setSidebarOpen(false);
    });

    return () => {
      unlisten();
    };
  }, [history]);

  const toggleMenu = () => {
    setSidebarOpen(!sidebarOpen);
  };

  return (
    <div className={"sidebarWrapper"}>
      <div
        className={
          !sidebarOpen ? "sideBarContainer" : "sideBarContainer active"
        }
      >
        <div className="d-flex mt-5 justify-content-center">
          <DarkModeTheme theme={theme} toggleTheme={toggleTheme} />
        </div>
        <ul>
          <li>
            <LinkContainer to="/">
              <Nav.Link>
                <HomeOutlined style={{ fontSize: "20px" }} />
                <div className="test">PROJECTS</div>
              </Nav.Link>
            </LinkContainer>
          </li>
          <li>
            <LinkContainer to="/pricing">
              <Nav.Link>
                <DollarCircleOutlined style={{ fontSize: "20px" }} />
                PRICING
              </Nav.Link>
            </LinkContainer>
          </li>
          <li>
            <LinkContainer to="/about">
              <Nav.Link>
                <UserOutlined style={{ fontSize: "20px" }} />
                ABOUT
              </Nav.Link>
            </LinkContainer>
          </li>
          <li>
            <LinkContainer to="/contact">
              <Nav.Link>
                <ContactsFilled style={{ fontSize: "20px" }} />
                CONTACT
              </Nav.Link>
            </LinkContainer>
          </li>
        </ul>
      </div>
      <div className={"toggle"} onClick={toggleMenu}>
        {sidebarOpen ? (
          <CloseOutlined style={{ fontSize: "30px" }} />
        ) : (
          <MenuOutlined style={{ fontSize: "30px" }} />
        )}
      </div>
    </div>
  );
};

export default SideBar;
