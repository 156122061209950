import React from "react";
import { Link } from "react-router-dom";
import dataTip from "data-tip";

// import Message from "./Message";

const HeaderIcons = ({ theme }) => {
  return (
    <div className="container" id="nav">
      <div className="row">
        <div className="text-center col-sm-12 text-sm-center d-sm-flex justify-content-sm-center align-items-sm-center col-lg-6 text-lg-left d-lg-flex justify-content-lg-start align-items-lg-center">
          <div className="push-nav d-flex justify-content-center align-items-center p-3">
            <Link to="/" className="data-tip-bottom" data-tip="Logo">
              <img
                src={
                  theme === "light"
                    ? "/images/logo-blk.svg"
                    : "/images/logo-white.svg"
                }
                className="img-fluid logo"
                alt="Jorden McDonald Logo"
                width="70px"
                height="70px"
              />
            </Link>
            <span
              id="logo-text"
              style={{
                letterSpacing: "2px",
                fontSize: "35px",
                fontWeight: "600",
                paddingLeft: "20px",
              }}
            >
              Jorden&nbsp;
            </span>
            <span
              id="logo-text"
              style={{
                letterSpacing: "2px",
                fontSize: "35px",
                fontWeight: "600",
                color: "#089AED",
              }}
            >
              McDonald
            </span>
          </div>
        </div>
        <div className="d-flex justify-content-center col-lg-6 justify-content-lg-end align-items-center p-0">
          <div className="socialIcons my-3">
            <a
              href="https://github.com/Jorden1420"
              target="blank"
              className="data-tip-bottom"
              data-tip="GitHub"
            >
              <i className="fab fa-github fa-2x" aria-hidden="true" />
            </a>
            <a
              href="https://www.facebook.com/jorden.mcdonald.96"
              target="blank"
              className="data-tip-bottom"
              data-tip="Facebook"
            >
              <i className="fa fa-facebook fa-2x" aria-hidden="true" />
            </a>
            {/* <a
                href=""
                target="blank"
                className="data-tip-bottom"
                data-tip="YouTube"
              >
                <i className="fa fa-youtube fa-2x" aria-hidden="true" />
              </a> */}
            <a
              href="https://www.linkedin.com/in/jorden-mcdonald-a1b90291/"
              target="blank"
              className="data-tip-bottom"
              data-tip="Linkedin"
            >
              <i className="fa fa-linkedin fa-2x" aria-hidden="true" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeaderIcons;
