import React from "react";

import { MailOutlined, GlobalOutlined } from "@ant-design/icons";

const FrontCard = ({ theme }) => {
  return (
    <div className="business-root">
      <div className="business-card-info m-auto d-flex justify-content-start">
        <p className="p-0 font-weight-bold" style={{ fontSize: "16px" }}>
          Business Card Front
        </p>
      </div>
      <div
        className="container border-bottom"
        id="business-media"
        style={{
          backgroundColor: theme === "light" ? "#fff" : "#141414",
          padding: "40px 40px 0px 40px",
        }}
      >
        <div className="business-card-container">
          <div className="business-card-header mb-3">
            <div className="logo d-flex align-items-center" id="logo-center">
              <img
                src={
                  theme === "light"
                    ? "/images/logo-sm-blk.png"
                    : "/images/logo-sm-white.png"
                }
                className="img-fluid logo"
                alt="Jorden McDonald Business Card Logo"
                width="60px"
                height="60px"
              />
              <span
                id="logo-text"
                style={{
                  fontSize: "26px",
                  paddingLeft: "20px",
                }}
              >
                Web Development
              </span>
            </div>
          </div>
        </div>
      </div>
      <div
        className="container"
        id="business-media"
        style={{
          backgroundColor: theme === "light" ? "#fff" : "#141414",

          padding: "20px 40px",
        }}
      >
        <div className="logo-content-container">
          <div className="row">
            <div className="col-sm-12 col-md-6 col-lg-6">
              <span
                id="logo-text"
                style={{
                  letterSpacing: "2px",
                  fontWeight: "600",
                  fontSize: "20px",
                }}
              >
                Jorden&nbsp;
              </span>
              <span
                id="logo-text"
                style={{
                  letterSpacing: "2px",
                  fontWeight: "600",
                  color: "#089AED",
                  fontSize: "20px",
                }}
              >
                McDonald
              </span>
              <p
                className="job-title p-0"
                style={{ color: "#1890FF", fontSize: "16px" }}
              >
                Front-end Developer
              </p>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-6">
              <div className="icon-section" style={{ padding: "2px" }}>
                <MailOutlined style={{ fontSize: "18px", color: "#098CD7" }} />
                <span>
                  <a
                    href="mailto:hey@jordenmcdonald.com"
                    className={
                      theme === "light"
                        ? "card-text pl-2 text-black"
                        : "card-text pl-2 text-white"
                    }
                  >
                    hey@jordenmcdonald.com
                  </a>
                </span>
              </div>
              <div className="icon-section" style={{ padding: "2px" }}>
                <GlobalOutlined
                  style={{ fontSize: "18px", color: "#098CD7" }}
                />
                <span>
                  <a
                    href="https://www.jordenmcdonald.com"
                    target="_blank"
                    rel="noopener noreferrer"
                    className={
                      theme === "light"
                        ? "card-text pl-2 text-black"
                        : "card-text pl-2 text-white"
                    }
                  >
                    www.jordenmcdonald.com
                  </a>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FrontCard;
