import React from "react";

import {
  InputNumber,
  Select,
  Form,
  Input,
  Radio,
  Button,
  Icon,
  Row,
  Col,
  DatePicker,
} from "antd";

import invoiceService from "../../../services/userProfile";

const ButtonGroup = Button.Group;
const { Option } = Select;
const { TextArea } = Input;

const statesData = [
  "Alabama",
  "Alaska",
  "American Samoa",
  "Arizona",
  "Arkansas",
  "California",
  "Colorado",
  "Connecticut",
  "Delaware",
  "District of Columbia",
  "Federated States of Micronesia",
  "Florida",
  "Georgia",
  "Guam",
  "Hawaii",
  "Idaho",
  "Illinois",
  "Indiana",
  "Iowa",
  "Kansas",
  "Kentucky",
  "Louisiana",
  "Maine",
  "Marshall Islands",
  "Maryland",
  "Massachusetts",
  "Michigan",
  "Minnesota",
  "Mississippi",
  "Missouri",
  "Montana",
  "Nebraska",
  "Nevada",
  "New Hampshire",
  "New Jersey",
  "New Mexico",
  "New York",
  "North Carolina",
  "North Dakota",
  "Northern Mariana Islands",
  "Ohio",
  "Oklahoma",
  "Oregon",
  "Palau",
  "Pennsylvania",
  "Puerto Rico",
  "Rhode Island",
  "South Carolina",
  "South Dakota",
  "Tennessee",
  "Texas",
  "Utah",
  "Vermont",
  "Virgin Island",
  "Virginia",
  "Washington",
  "West Virginia",
  "Wisconsin",
  "Wyoming",
];

class AdminTemplate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userID: this.props.userID,
      states: statesData,
      invoiceitemQuantity: 1,
      invoiceRate: 35,
      invoiceTotal: "",
      successForm: false,
      validateStatus: "",
      fromDate: "",
      toDate: "",
      invoiceType: 1,
      invoicePaid: 1,
    };
  }

  invoiceRate = (number) => {
    this.setState({
      invoiceRate: number,
    });
  };

  invoiceitemQuantity = (number) => {
    this.setState({
      invoiceitemQuantity: number,
    });
  };

  timeSpent = (number) => {
    this.setState({
      decimalTime: number.target.value,
    });
  };

  dateChange = (date, dateString) => {
    // console.log(date, dateString);
    this.setState({
      fromDate: dateString[0],
      toDate: dateString[1],
    });
  };

  onSubmit = (e) => {
    e.preventDefault();

    let _this = this;

    this.props.form.validateFields(async (err, values) => {
      // If all fields were successfully validated

      if (err) {
        this.setState({
          validateStatus: "error",
        });
      }

      if (!err) {
        let invoiceRate = values.invoiceRate;
        let invoicetotalTime = values.timeSpent;

        let totalData = invoiceRate * Number(invoicetotalTime);

        // Validate then success
        let validating = setTimeout(function () {
          _this.setState({
            validateStatus: "validating",
          });
          setTimeout(async function () {
            _this.setState({
              validateStatus: "success",
            });

            let invoiceData = {
              userID: _this.state.userID,
              companyCity: values.companyCity,
              companyAddress: values.companyAddress,
              companyName: values.companyName,
              companyState: values.companyState,
              companyZip: values.companyZip,
              invoicefromDate: _this.state.fromDate,
              invoicetoDate: _this.state.toDate,
              invoiceItem: values.invoiceItem,
              invoiceDescription: values.invoiceDescription,
              invoiceNotes: values.invoiceNotes,
              invoiceNumber: values.invoiceNumber,
              invoiceQuantity: values.invoiceQuantity,
              invoiceRate:
                _this.state.invoiceType === 1 ? values.invoiceRate : null,
              invoicebillemailTo: values.invoicebillemailTo,
              invoicebillpersonTo: values.invoicebillpersonTo,
              timeSpent: values.timeSpent,
              // Hourly Invoice Total
              invoiceTotal: _this.state.invoiceType === 1 ? totalData : null,
              // Fixed Invoice Total
              invoicefixedTotal:
                _this.state.invoiceType === 2 ? values.fixedRate : null,
              invoiceType: _this.state.invoiceType === 1 ? "Hourly" : "Fixed",
              invoicePaid: _this.state.invoicePaid === 1 ? "No" : "Yes",
            };

            // console.log(invoiceData);

            // Send the data
            let sendInvoice = await invoiceService.createInvoice(invoiceData);
          }, 1000);
        }, 1000);

        await this.setState({
          invoiceRate: this.state.invoiceType === 1 ? invoiceRate : null,
          invoicetotalTime: invoicetotalTime,
          invoiceTotal: this.state.invoiceType === 1 ? totalData : null,
          successForm: true,
        });

        this.props.form.resetFields();
      }
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const { RangePicker } = DatePicker;
    return (
      <div className="templateModule">
        <h1>Create Invoice</h1>
        <Form layout="vertical" onSubmit={(e) => this.onSubmit(e)}>
          <Row gutter={16}>
            <Col md={12}>
              <Form.Item label="Invoice #">
                {getFieldDecorator("invoiceNumber", {
                  rules: [
                    { required: true, message: "Please enter an invoice #" },
                  ],
                })(<Input addonBefore={"JM"} placeholder="Invoice #" />)}
              </Form.Item>
            </Col>
            <Col md={12}>
              <Form.Item label="Company Name">
                {getFieldDecorator("companyName", {
                  rules: [
                    { required: true, message: "Please enter a company name" },
                  ],
                })(<Input placeholder="Company Name" />)}
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Form.Item label="Invoice From/To Date">
              <RangePicker onChange={this.dateChange} />
            </Form.Item>
          </Row>
          <Row gutter={16}>
            <Col md={12}>
              <Form.Item label="Company Address">
                {getFieldDecorator("companyAddress", {
                  rules: [
                    {
                      required: true,
                      message: "Please enter a company address",
                    },
                  ],
                })(
                  <Input name="companyAddress" placeholder="Company Address" />
                )}
              </Form.Item>
            </Col>
            <Col md={12}>
              <Form.Item label="Company City">
                {getFieldDecorator("companyCity", {
                  rules: [
                    { required: true, message: "Please enter company city" },
                  ],
                })(<Input placeholder="Company City" />)}
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col md={12}>
              <Form.Item label="Company State">
                {getFieldDecorator("companyState", {
                  rules: [
                    {
                      required: true,
                      message: "Please select a company state",
                    },
                  ],
                })(
                  <Select showSearch placeholder="Company State">
                    {this.state.states.map((city) => (
                      <Option value={city} key={city}>
                        {city}
                      </Option>
                    ))}
                  </Select>
                )}
              </Form.Item>
            </Col>
            <Col md={12}>
              <Form.Item label="Company Zip">
                {getFieldDecorator("companyZip", {
                  rules: [
                    {
                      required: true,
                      message: "Please enter company zip code",
                    },
                  ],
                })(<Input placeholder="Company Zip Code" />)}
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col md={12}>
              <Form.Item label="Invoice Bill Person To">
                {getFieldDecorator("invoicebillpersonTo", {
                  rules: [
                    {
                      required: true,
                      message: "Please enter person to bill to",
                    },
                  ],
                })(<Input placeholder="Invoice Bill Person To" />)}
              </Form.Item>
            </Col>
            <Col md={12}>
              <Form.Item label="Invoice Bill Email To">
                {getFieldDecorator("invoicebillemailTo", {
                  rules: [
                    {
                      required: true,
                      message: "Please input an email address",
                    },
                    {
                      type: "email",
                      message: "That is not a valid email address",
                    },
                  ],
                })(<Input placeholder="Invoice Bill Email To" />)}
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col md={12}>
              <Form.Item label="Invoice Description">
                {getFieldDecorator("invoiceDescription", {
                  rules: [
                    {
                      required: true,
                      message: "Please enter a description",
                    },
                  ],
                })(<Input placeholder="Invoice Description" />)}
              </Form.Item>
            </Col>
            <Col md={12}>
              <Form.Item label="Invoice Item">
                {getFieldDecorator("invoiceItem", {
                  rules: [
                    {
                      required: true,
                      message: "Please enter an invoice item",
                    },
                  ],
                })(<Input placeholder="Invoice Item" />)}
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Form.Item label="Invoice Notes">
              {getFieldDecorator("invoiceNotes")(
                <TextArea rows={4} placeholder="Invoice Notes" />
              )}
            </Form.Item>
          </Row>
          <Row>
            <Col md={12}>
              <Form.Item label="Invoice Type">
                <Radio.Group
                  onChange={(e) =>
                    this.setState({ invoiceType: e.target.value })
                  }
                  value={this.state.invoiceType}
                >
                  <Col md={12}>
                    <Radio value={1} checked>
                      Hourly
                    </Radio>
                  </Col>
                  <Col md={12}>
                    <Radio value={2}>Fixed</Radio>
                  </Col>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col md={12}>
              <Form.Item label="Invoice Paid?">
                <Radio.Group
                  onChange={(e) =>
                    this.setState({ invoicePaid: e.target.value })
                  }
                  value={this.state.invoicePaid}
                >
                  <Col md={12}>
                    <Radio value={1} checked>
                      No
                    </Radio>
                  </Col>
                  <Col md={12}>
                    <Radio value={2}>Yes</Radio>
                  </Col>
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <Form.Item label="Invoice Quantity">
                {getFieldDecorator("invoiceQuantity", {
                  initialValue: this.state.invoiceitemQuantity,
                })(<InputNumber min={1}></InputNumber>)}
              </Form.Item>
            </Col>
            <Col md={12}>
              <Form.Item label="Total Time Spent">
                {getFieldDecorator("timeSpent", {
                  initialValue: 0,
                })(
                  <Input
                    prefix={
                      <Icon
                        type="clock-circle"
                        style={{ color: "rgba(0,0,0,.25)" }}
                      />
                    }
                  />
                )}
              </Form.Item>
            </Col>
          </Row>
          <Row>
            {this.state.invoiceType === 1 ? (
              <Form.Item label="Invoice Rate">
                {getFieldDecorator("invoiceRate", {
                  initialValue: this.state.invoiceRate,
                })(
                  <InputNumber
                    formatter={(value) =>
                      `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    }
                    parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                    onChange={(number) => this.invoiceRate(number)}
                  ></InputNumber>
                )}
              </Form.Item>
            ) : (
              <Form.Item label="Fixed Rate">
                {getFieldDecorator("fixedRate", {
                  rules: [
                    {
                      required: true,
                      message: "Please enter a the fixed rate",
                    },
                  ],
                })(
                  <Input
                    prefix={
                      <Icon
                        type="dollar"
                        style={{ color: "rgba(0,0,0,.25)" }}
                      />
                    }
                  />
                )}
              </Form.Item>
            )}
          </Row>
          <Form.Item hasFeedback validateStatus={this.state.validateStatus}>
            <Button
              type="primary"
              style={{ width: "200px" }}
              htmlType="submit"
              ghost
            >
              Submit Invoice
            </Button>
          </Form.Item>
          <Form.Item>
            {this.state.invoiceType === 1 ? (
              <p style={{ color: "#fff" }}>
                {this.state.invoicetotalTime === undefined
                  ? this.state.invoiceRate +
                    "/hr * " +
                    0 +
                    "/total time = " +
                    "$" +
                    0
                  : this.state.invoiceRate +
                    "/hr * " +
                    this.state.invoicetotalTime +
                    "/total time = " +
                    "$" +
                    this.state.invoiceTotal.toLocaleString()}
              </p>
            ) : null}
          </Form.Item>
        </Form>
      </div>
    );
  }
}

const WrappedNormalLoginForm = Form.create({ name: "admin_template_form" })(
  AdminTemplate
);

export default WrappedNormalLoginForm;
