import React from "react";
import { Helmet } from "react-helmet";

import { Link } from "react-router-dom";
import { WarningFilled } from "@ant-design/icons";

import AOS from "aos"; // Animate on scroll

const NoMatch = ({ theme }) => {
  AOS.init();
  return (
    <div className="fluid-container">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Jorden McDonald Portfolio 404 Page Not Found</title>
        <meta
          name="description"
          content="Jorden McDonald Portfolio page requested was not found"
        />
        <link rel="canonical" href="https://www.jordenmcdonald.com/noMatch" />
      </Helmet>
      <div className="Nomatchcontainer">
        <div className="container">
          <div
            className="imgCover"
            style={{
              height: "100vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              className="nomatchText text-center"
              data-aos="fade-down"
              data-aos-once="true"
            >
              <WarningFilled
                style={{
                  fontSize: "10rem",
                  color: theme === "light" ? "#007BFF" : "#F8FF38",
                }}
              />
              <h2
                style={{
                  fontSize: "2rem",
                  fontWeight: "bold",
                  color: theme === "light" ? "text-black" : "#007BFF",
                }}
              >
                404 - Page Not Found
              </h2>
              <div className="link-home mt-3">
                <p
                  className={
                    theme === "light"
                      ? "text-center text-black"
                      : "text-center text-white"
                  }
                  style={{ fontWeight: "bold" }}
                >
                  Oops! We can't seem to find the page you're looking for. Sorry
                  about that!
                  <Link className="btn btn-primary d-block m-2" to="/">
                    Back to Home
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NoMatch;
