import React, { Fragment } from "react";

import { Alert } from "antd";

const Message = () => {
  return (
    <Fragment>
      <Alert
        message="All web sites and web applications were done entirely by Jorden McDonald to meet client business needs and requests. This includes front-end, back-end, database architecture as well as the UI/UX design."
        type="info"
        showIcon
        closable
        className="mb-3"
      />
    </Fragment>
  );
};

export default Message;
