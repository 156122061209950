import React, { Component } from "react";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";

import { Helmet } from "react-helmet";

// import AddBlogPost from "./AddBlogPost";
import BlogSection from "../BlogPage/BlogSection";
import DisplayWebProjects from "./DisplayWebProjects";

import Message from "../../common/Message";
import BlogPageService from "../../../services/BlogService";
import WebPageService from "../../../services/WebService";

class HomePage extends Component {
  constructor(props) {
    super(props);

    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);

    this.selectblogTopics = this.selectblogTopics.bind(this);

    this.state = {
      isAdmin: false,
      visible: 2,
      blogData: [],
      webData: [],
    };
  }

  handleClose() {
    this.setState({ show: false });
  }

  handleShow() {
    this.setState({ show: true });
  }

  // Used for loading more posts if user requests it.
  handleShowMorePosts = () => {
    this.setState((prev) => {
      return { visible: prev.visible + 1 };
    });
  };

  async selectblogTopics() {
    const selectblogData = await BlogPageService.SelectBlogTopics();

    this.setState({ blogData: selectblogData });
  }

  async selectwebData() {
    const selectwebData = await WebPageService.SelectWebProjects();

    this.setState({ webData: selectwebData });
  }

  async componentDidMount() {
    // Check if user is logged in
    if (localStorage.getItem("userData") !== null) {
      const userData = JSON.parse(localStorage.getItem("userData"));
      if (userData.role_id == 3) {
        this.setState({ isAdmin: true });
      }
    }

    await this.selectblogTopics();
    await this.selectwebData();
  }

  render() {
    const { theme } = this.props;
    return (
      <div className="container-fluid">
        <Helmet>
          <meta charSet="utf-8" />
          <title>Jorden McDonald Portfolio</title>
          <meta
            name="description"
            content="Jorden McDonald Front End Developer offering services for building websites, web applications for specific clients requests."
          />
          <link rel="canonical" href="https://www.jordenmcdonald.com" />
        </Helmet>
        <div className="container" id="webpage">
          <div className="row">
            <div className="col-sm-12 col-lg-8">
              <h1 className="pt-5">Web Projects</h1>
              <div className="project-container">
                {/* Message */}
                <Message />
                {/* DisplayWebProjects */}
                <DisplayWebProjects
                  webData={this.state.webData}
                  visible={this.state.visible}
                />
              </div>
              {this.state.webData.length !== this.state.visible ? (
                <div className="loadMore text-center mb-3">
                  <Button onClick={() => this.handleShowMorePosts()}>
                    Load More Projects
                  </Button>
                </div>
              ) : null}
            </div>
            <div
              className="col-sm-12 col-lg-4"
              style={{
                backgroundColor:
                  theme === "light" ? "#36576b" : "rgba(0, 0, 0, 0.5)",
              }}
            >
              <div className="web-right-container">
                <h1 className="pt-5">Recent Blog Posts</h1>
                {/* {this.state.isAdmin == true ? (
                  <div className="addblogPost">
                    <Button id="addblogButton" onClick={this.handleShow}>
                      <i
                        className="fa fa-plus-square-o"
                        aria-hidden="true"
                        title="Add Blog Post"
                      />
                    </Button>
                    <Modal show={this.state.show} onHide={this.handleClose}>
                      <Modal.Header closeButton>
                        <Modal.Title>Add Blog Post</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <AddBlogPost
                          handleClose={this.handleClose}
                          selectblogTopics={this.selectblogTopics.bind(this)}
                        />
                      </Modal.Body>
                    </Modal>
                  </div>
                ) : null} */}
                {/* Blog Section */}
                <BlogSection blogData={this.state.blogData} />
              </div>
              <div
                className="col-md-12"
                id="resume"
                style={{
                  paddingLeft: "0px",
                  paddingTop: "1rem",
                  paddingBottom: "1rem",
                }}
              >
                <h1>Resume</h1>
                <Link
                  to="/Contact"
                  className="btn btn-outline-primary"
                  id="hire"
                >
                  Hire Me
                </Link>
                <div id="resumeicon">
                  <span className="data-tip-bottom" data-tip="PDF Resume">
                    <a
                      href="https://jmsiteimages.s3.us-west-1.amazonaws.com/jordenmcdonaldresumefv2.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i
                        className="fa fa-file-pdf-o fa-2x"
                        aria-hidden="true"
                        style={{ borderRight: "2px solid white" }}
                      />
                    </a>
                  </span>
                  <span className="data-tip-bottom" data-tip="Word Resume">
                    <a
                      href="https://jmsiteimages.s3.us-west-1.amazonaws.com/jordenmcdonaldresumefv2.docx"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i
                        className="fa fa-file-word-o fa-2x"
                        aria-hidden="true"
                        style={{ borderRight: "2px solid white" }}
                      />
                    </a>
                  </span>
                </div>
              </div>
              <div
                className="col-md-12"
                id="certifications"
                style={{
                  paddingLeft: "0px",
                  paddingTop: "1rem",
                  paddingBottom: "1rem",
                }}
              >
                <h1>Certifications</h1>
                <div className="certicon">
                  <span className="data-tip-bottom" data-tip="HTML5">
                    <a
                      href="https://www.coursera.org/account/accomplishments/certificate/EAK8PX6RZGXL"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="fab fa-html5 fa-2x" />
                    </a>
                  </span>
                  <span className="data-tip-bottom" data-tip="CSS3">
                    <a
                      href="https://www.coursera.org/account/accomplishments/certificate/EAK8PX6RZGXL"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="fab fa-css3-alt fa-2x" />
                    </a>
                  </span>
                  <span className="data-tip-bottom" data-tip="JavaScript">
                    <a
                      href="https://www.coursera.org/account/accomplishments/certificate/D6EGRPJXKA3D"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="fab fa-js fa-2x" />
                    </a>
                  </span>
                  <span className="data-tip-bottom" data-tip="ReactJS">
                    <a
                      href="https://www.coursera.org/account/accomplishments/certificate/D6EGRPJXKA3D"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="fab fa-react fa-2x" />
                    </a>
                  </span>
                  <span className="data-tip-bottom" data-tip="NodeJS">
                    <a
                      href="https://www.coursera.org/account/accomplishments/certificate/Y4KTGW5WPKSN"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="fab fa-node fa-2x" />
                    </a>
                  </span>
                  <span className="data-tip-bottom" data-tip="Sass">
                    <a
                      href="https://www.coursera.org/account/accomplishments/certificate/EAK8PX6RZGXL"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="fab fa-sass fa-2x" />
                    </a>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default HomePage;
