import React from "react";

import { Form, Input, Button, Row, Col, Icon, Tooltip } from "antd";
import { PasswordInput } from "antd-password-input-strength";
import Recaptcha from "react-google-recaptcha";

import registerService from "../../../services/RegisterService";

class RegisterPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: "",
      lastName: "",
      userName: "",
      email: "",
      password: "",
      captchaVerify: false,
      confirmPassword: "",
      errorMsg: false,
      errorMsg2: false,
      successMsg: false,
      capslockEnabled: false,
    };
  }

  captchaVerify = (value) => {
    const capVerify = value;
    if (capVerify !== "") {
      this.setState({
        captchaVerify: true,
      });
    }
  };

  // Check if caps lock is currently on or not
  onKeyDown(e) {
    if (e.shiftKey === true) {
      this.setState({ capslockEnabled: true });
    }
  }

  onKeyUp(e) {
    if (e.keyCode === 16) {
      this.setState({ capslockEnabled: false });
    }
  }

  compareToFirstPassword = (rule, value, callback) => {
    const { form } = this.props;
    if (value && value !== form.getFieldValue("password")) {
      callback("Two passwords that you enter is inconsistent!");
    } else {
      callback();
    }
  };

  validateToNextPassword = (rule, value, callback) => {
    const { form } = this.props;
    if (value && this.state.confirmDirty) {
      form.validateFields(["confirm"], { force: true });
    }
    callback();
  };

  onSubmit(e) {
    e.preventDefault();

    this.props.form.validateFields(async (err, values) => {
      if (!err) {
        const data = {
          firstName: values.firstName,
          lastName: values.lastName,
          userName: values.username,
          email: values.email,
          password: values.password,
          confirmPassword: values.confirmPassword,
        };
        const checkuserData = await registerService.Checkuser(data);
        if (checkuserData.length === 1) {
          this.setState({
            errorMsg: true,
          });
        } else if (this.state.captchaVerify === false) {
          this.setState({
            errorMsg2: true,
          });
        } else {
          this.setState({
            errorMsg: false,
            errorMsg2: false,
            successMsg: true,
          });

          var registerData = await registerService.Auth(data);

          this.props.form.resetFields();
        }
      }
    });
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <div className="container">
        <div className="registerformContainer" style={{ marginTop: "15%" }}>
          {/* User is already in the database. */}
          {this.state.errorMsg === true ? (
            <p style={{ color: "red", textAlign: "center" }}>
              Sorry this username already exists. Please choose another
              username.
            </p>
          ) : null}
          {/* Caps Lock Enabled */}
          {this.state.capslockEnabled === true ? (
            <div className="alert alert-info">Caps Lock Enabled</div>
          ) : null}
          {/* Recaptcha not filled out */}
          {this.state.errorMsg2 === true ? (
            <p style={{ color: "red", textAlign: "center" }}>
              Please verify you're not a robot.
            </p>
          ) : null}
          {/* User Successfully Registered */}
          {this.state.successMsg === true ? (
            <p style={{ color: "green", textAlign: "center" }}>
              You have successfully been registered.
            </p>
          ) : null}
          <Form onSubmit={(e) => this.onSubmit(e)}>
            <Row type="flex" justify="start">
              <Col md={16}>
                <Tooltip title="Password must contain 1 lowercase, 1 uppercase and 1 special character.">
                  <Icon
                    type="info-circle"
                    style={{ color: "rgb(255,255,255)" }}
                  />
                </Tooltip>
              </Col>
            </Row>
            <Row type="flex" justify="center" gutter={12}>
              <Col md={8}>
                <Form.Item>
                  {getFieldDecorator("firstName", {
                    rules: [
                      {
                        required: true,
                        message: "Please enter your first name.",
                      },
                    ],
                  })(<Input placeholder="First Name" />)}
                </Form.Item>
              </Col>
              <Col md={8}>
                <Form.Item>
                  {getFieldDecorator("lastName", {
                    rules: [
                      {
                        required: true,
                        message: "Please enter your last name.",
                      },
                    ],
                  })(<Input placeholder="Last Name" />)}
                </Form.Item>
              </Col>
            </Row>
            <Row type="flex" justify="center" gutter={12}>
              <Col md={8}>
                <Form.Item>
                  {getFieldDecorator("username", {
                    rules: [
                      {
                        required: true,
                        message: "Please choose a username to register",
                      },
                      {
                        required: true,
                        max: 15,
                        message: "Username cannot exceed 15 characters",
                      },
                    ],
                  })(
                    <Input
                      prefix={
                        <Icon
                          type="user"
                          style={{ color: "rgba(0,0,0,.25)" }}
                        ></Icon>
                      }
                      placeholder="Username"
                    />
                  )}
                </Form.Item>
              </Col>
              <Col md={8}>
                <Form.Item>
                  {getFieldDecorator("email", {
                    rules: [
                      {
                        type: "email",
                        message: "That is not a valid email address!",
                      },
                      {
                        required: true,
                        message: "Please enter an email address to register",
                      },
                    ],
                  })(<Input placeholder="Email Address" />)}
                </Form.Item>
              </Col>
            </Row>
            <Row type="flex" justify="center" gutter={12}>
              <Col md={8}>
                <Form.Item>
                  {getFieldDecorator("password", {
                    rules: [
                      {
                        required: true,
                        message: "Please input your password!",
                      },
                      {
                        required: true,
                        pattern:
                          "^(?=.{8,})(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&+*!=]).*$",
                        message:
                          "Password must contain 1 uppercase, 1 lowercase and 1 special character",
                      },
                      {
                        required: true,
                        min: 8,
                        message: "Password must be 8 characters or longer",
                      },
                      {
                        validator: this.validateToNextPassword,
                      },
                    ],
                  })(
                    <PasswordInput
                      prefix={
                        <Icon
                          type="lock"
                          style={{ color: "rgba(0,0,0,.25)" }}
                        ></Icon>
                      }
                      placeholder="Password"
                    />
                  )}
                </Form.Item>
              </Col>
              <Col md={8}>
                <Form.Item>
                  {getFieldDecorator("confirmPassword", {
                    rules: [
                      {
                        required: true,
                        message: "Please confirm your password!",
                      },
                      {
                        validator: this.compareToFirstPassword,
                      },
                    ],
                  })(
                    <PasswordInput
                      prefix={
                        <Icon
                          type="lock"
                          style={{ color: "rgba(0,0,0,.25)" }}
                        ></Icon>
                      }
                      placeholder="Confirm Password"
                    />
                  )}
                </Form.Item>
              </Col>
            </Row>
            <div className="recaptcha">
              <Row type="flex" justify="center">
                <Recaptcha
                  theme="dark"
                  style={{
                    marginTop: "20px",
                  }}
                  sitekey={process.env.RECAPTCHA_API_KEY}
                  onChange={this.captchaVerify}
                />
              </Row>
            </div>
            <Row type="flex" justify="center">
              <Button type="primary" htmlType="submit" id="loginButton">
                Register
              </Button>
            </Row>
            <Row type="flex" justify="center">
              <Button
                type="link"
                onClick={() => this.props.history.push("login")}
              >
                Login
              </Button>
            </Row>
          </Form>
        </div>
      </div>
    );
  }
}

const RegisterWrapper = Form.create({ name: "register_form" })(RegisterPage);

export default RegisterWrapper;
