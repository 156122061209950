import React, { useEffect, useState } from "react";
import { Form, Input, Button, Row, Col, Alert } from "antd";
import { useHistory } from "react-router-dom";
import invoiceDetails from "../../../services/UserProfile";

const InvoicePage = () => {
  const history = useHistory();
  const [invoiceData, setInvoiceData] = useState([]);
  const [invoiceNumber, setInvoiceNumber] = useState("");
  const [lastName, setLastName] = useState("");
  const [alertVisible, setAlertVisible] = useState(false);
  const [errorVisible, setErrorVisible] = useState(false);

  useEffect(() => {}, [invoiceData, invoiceNumber]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "invoiceNumber") {
      if (!isNaN(value) || value === "") {
        setInvoiceNumber(value);
        setAlertVisible(false);
        setErrorVisible(false);
      }
    } else if (name === "lastName") {
      setLastName(value);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // console.log("values are..", invoiceNumber, lastName);

    // Hide any existing alerts
    setAlertVisible(false);
    setErrorVisible(false);

    // Fetch invoice details
    try {
      const response = await invoiceDetails.selectInvoicesByID({
        invoiceNumber: invoiceNumber,
        lastName: lastName,
      });

      // If no invoice data is found in the response, show an error alert
      if (!response || response.length === 0) {
        setErrorVisible(true);
        return; // Return early to prevent further execution
      }

      // If invoice data is found but invoice_paid is "Yes", show an error alert
      if (response[0].invoice_paid === "Yes") {
        setErrorVisible(true);
        return; // Return early to prevent further execution
      }

      // If invoice data is found and invoice_paid is not "Yes", navigate to the details page
      history.push(`/invoice-details/${invoiceNumber}/${lastName}`);
    } catch (error) {
      // Show an error alert if there's an error while fetching invoice details
      console.error("Error fetching invoice details:", error);
      setErrorVisible(true);
    }
  };

  return (
    <div className="container d-flex justify-content-center my-5">
      <div
        className="invoice-container p-5"
        style={{ backgroundColor: "#212D64", borderRadius: "35px" }}
      >
        <h1 style={{ color: "#fff" }}>Search for Invoice</h1>
        <i
          className="fa fa-cc-stripe fa-5x pb-3"
          aria-hidden="true"
          style={{
            color: "#fff",
          }}
        />
        <p style={{ color: "#fff", fontSize: "20px" }}>
          This payment system uses Stripe to process payments. All payments are
          processed securely.
        </p>
        <p style={{ color: "#fff", fontSize: "20px" }}>
          Enter in your last name as well as the invoice number that was mailed
          or emailed to you and we'll look up the invoice to where you can
          submit payment.
        </p>
        <div className="search-invoice-container">
          <Form className="contact-form" onSubmit={handleSubmit}>
            <Row type="flex" justify="center">
              <Col md={24} sm={24} xs={24}>
                <Form.Item label="Your Last Name" style={{ color: "#000" }}>
                  <Input
                    size="large"
                    placeholder="Enter Your Last Name on Invoice"
                    value={lastName}
                    onChange={handleInputChange}
                    name="lastName"
                    type="text"
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row type="flex" justify="center">
              <Col md={24} sm={24} xs={24}>
                <Form.Item label="Invoice #" style={{ color: "#000" }}>
                  <Input
                    size="large"
                    placeholder="Enter Invoice Number on Invoice"
                    value={invoiceNumber}
                    onChange={handleInputChange}
                    name="invoiceNumber"
                    type="number" // Accepts only numbers
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row type="flex" justify="center">
              <Col md={24}>
                {errorVisible && (
                  <Alert
                    message="We cannot find any information related to that invoice."
                    type="error"
                    showIcon
                    closable
                    onClose={() => setAlertVisible(false)}
                    style={{ marginBottom: "15px" }}
                  />
                )}
                <Button
                  type="primary"
                  htmlType="submit"
                  id="invoiceButton"
                  size="large"
                  disabled={!invoiceNumber.trim() || lastName.trim() === ""}
                >
                  Search Invoice
                </Button>
              </Col>
            </Row>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default InvoicePage;
