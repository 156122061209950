import React, { useState, useEffect } from "react";

import { Link } from "react-router-dom";

import BlogPageSkeleton from "../Skeletons/BlogPageSkeleton";

import BlogPageService from "../../../services/blogService";

const BlogPage = (props) => {
  const { blogid } = props.match.params;
  const { theme } = props;
  const [blogData, setBlogData] = useState();

  useEffect(() => {
    try {
      const getBlogData = async () => {
        const selectblogData = await BlogPageService.blogpageTopic({
          blogId: blogid,
        });

        setBlogData(selectblogData);
      };

      getBlogData();
    } catch (err) {
      console.log("error is..", err);
    }
  }, []);

  useEffect(() => {
    // console.log("test...", blogData);
  }, [blogData]);

  // console.log(blogData);

  return (
    <div className="blog-container">
      <div className="container-fluid">
        <div className="blog-header">
          <div className="mainprojectCover">
            <h1>
              {blogData && blogData[0].blog_topic ? (
                blogData[0].blog_topic
              ) : (
                <BlogPageSkeleton render={"blogtopic"} />
              )}
            </h1>
          </div>
          <div className="container blog-container">
            <nav aria-label="breadcrumb" className="mt-3">
              <ol
                className="breadcrumb align-items-center"
                style={{ backgroundColor: "transparent", fontWeight: "bold" }}
              >
                <li className="breadcrumb-item">
                  <Link to="/">Home</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Blog:{" "}
                  {blogData && blogData[0].blog_topic ? (
                    blogData[0].blog_topic
                  ) : (
                    <BlogPageSkeleton render={"blogtopic"} />
                  )}
                </li>
              </ol>
            </nav>
            <div className="blog-hero-image mt-3">
              <img
                src={
                  blogData && blogData[0].profilePic ? (
                    `https://s3-us-west-1.amazonaws.com/profileimages-jorden/blogimages/${blogData[0].blog_img}`
                  ) : (
                    <BlogPageSkeleton render={"profilepic"} />
                  )
                }
                style={{
                  width: "100%",
                  height: "250px",
                  objectFit: "cover",
                  borderRadius: "20px",
                }}
                alt="Blog Photo"
              />
            </div>
            <div className="blog-content">
              {blogData && blogData[0].blog_content ? (
                blogData[0].blog_content.split("<br/>").map((text, index) => (
                  <p
                    className={
                      theme === "light" ? "text-black p-3" : "text-white p-2"
                    }
                    style={{ fontSize: "20px" }}
                    key={index}
                  >
                    {text}
                  </p>
                ))
              ) : (
                <BlogPageSkeleton render={"blogcontent"} />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogPage;
