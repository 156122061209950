/*eslint-disable no-unused-vars*/
import React from "react";

const Footer = ({ theme }) => {
  var today = new Date();
  return (
    <footer className="py-3">
      <center>
        <p className={theme === "light" ? "text-black" : "text-white"}>
          &copy; {today.getFullYear()} Jorden <span>McDonald</span> | All rights
          reserved
        </p>
      </center>
    </footer>
  );
};

export default Footer;
