import React, { Component } from "react";

import { Form, Input, Button, Row, Col, Alert } from "antd";
import { MailOutlined } from "@ant-design/icons";
import Recaptcha from "react-google-recaptcha";
import { Helmet } from "react-helmet";

import contactService from "../../../services/contactService";

class ContactPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fullName: "",
      email: "",
      subject: "",
      message: "",
      captchaVerify: false,
      errorMsg: false,
      errorMsg2: false,
      successMsg: false,
    };
  }

  captchaVerify = (value) => {
    const capVerify = value;
    if (capVerify !== "") {
      this.setState({
        captchaVerify: true,
      });
    }
  };

  onSubmit(e) {
    e.preventDefault();

    this.props.form.validateFields(async (err, values) => {
      // console.log("errors ", err, "values ", values);
      if (!err) {
        const data = {
          fullName: values.fullname,
          email: values.email,
          subject: values.subject,
          message: values.message,
        };
        if (this.state.captchaVerify === false) {
          this.setState({ errorMsg2: true });
        } else {
          this.props.form.resetFields();
          this.setState({
            errorMsg2: false,
            successMsg: true,
          });

          var contactData = await contactService.emailUser(data);
        }
      }
    });
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    const { TextArea } = Input;
    const { theme } = this.props;
    return (
      <div className="container-fluid">
        <Helmet>
          <meta charSet="utf-8" />
          <title>Jorden McDonald Portfolio Contact Information</title>
          <meta
            name="description"
            content="Jorden McDonald Portfolio Contact | Have a question? Feel free to get in contact me about your business needs and requests and I'll be sure to get back to you as soon as possible!"
          />
          <link rel="canonical" href="https://www.jordenmcdonald.com/contact" />
        </Helmet>
        <div className="container">
          <div className="genericcontainer">
            <h1 className="pt-5">Contact</h1>
            <p
              className={
                theme === "light" ? "text-black pb-3" : "text-white pb-3"
              }
            >
              Have a question? I'll be happy to answer it!
            </p>

            <div className="row">
              <div
                className="col-lg-4 p-3 col-md mb-3"
                style={{ backgroundColor: "#089AED" }}
              >
                <h2 className="text-white">Contact Information</h2>
                <p className="text-white text-left">
                  Simply fill out the form and I'll make sure to get back to you
                  as soon as possible.
                </p>
                <div className="contactInfo my-3">
                  <span style={{ fontSize: "18px" }}>
                    <a
                      className="text-white"
                      href="mailto:hey@jordenmcdonald.com"
                    >
                      {" "}
                      <MailOutlined style={{ fontSize: "24px" }} />{" "}
                      hey@jordenmcdonald.com
                    </a>
                  </span>
                </div>
              </div>
              <div className="col-lg-6">
                <Alert
                  message="Required fields are marked with *"
                  type="info"
                  showIcon
                  closable
                />
                <Form
                  className="contact-form"
                  onSubmit={(e) => this.onSubmit(e)}
                >
                  <Row type="flex" justify="center">
                    <Col md={24} sm={24} xs={24}>
                      <Form.Item label="Full Name">
                        {getFieldDecorator("fullname", {
                          rules: [
                            {
                              required: true,
                              message: "Please enter your full name",
                            },
                          ],
                        })(<Input placeholder="Full Name" />)}
                      </Form.Item>
                    </Col>
                    <Col md={24} sm={24} xs={24}>
                      <Form.Item label="Email Address">
                        {getFieldDecorator("email", {
                          rules: [
                            {
                              type: "email",
                              message: "That is not a valid email address!",
                            },
                            {
                              required: true,
                              message: "Please enter an email address",
                            },
                          ],
                        })(<Input placeholder="Email Address" />)}
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row type="flex" justify="center">
                    <Col md={24} sm={24} xs={24}>
                      <Form.Item label="Subject">
                        {getFieldDecorator("subject", {
                          rules: [
                            {
                              required: true,
                              message: "Please enter reason for contact",
                            },
                          ],
                        })(<Input placeholder="Subject" />)}
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row type="flex" justify="center">
                    <Col md={24} sm={24} xs={24}>
                      <Form.Item label="Message">
                        {getFieldDecorator("message")(
                          <TextArea rows={5} placeholder="Message" />
                        )}
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row type="flex" justify="center">
                    <Col md={24}>
                      <Form.Item>
                        <Recaptcha
                          style={{
                            marginTop: "20px",
                          }}
                          sitekey={process.env.RECAPTCHA_API_KEY}
                          onChange={this.captchaVerify}
                        />
                      </Form.Item>
                      {/* Please fill in all fields */}
                      {this.state.errorMsg === true ? (
                        <p style={{ color: "red" }}>
                          Please fill out all fields.
                        </p>
                      ) : null}
                      {/* Fill in Recaptcha */}
                      {this.state.errorMsg2 === true ? (
                        <p style={{ color: "red" }}>
                          Please verify you're not a robot.
                        </p>
                      ) : null}
                      {/* Send Email */}
                      {this.state.successMsg === true ? (
                        <p style={{ color: "green" }}>
                          Thank you, message has been successfully sent.
                        </p>
                      ) : null}
                    </Col>
                  </Row>

                  <Row type="flex" justify="center">
                    <Col md={24}>
                      <Button
                        type="primary"
                        htmlType="submit"
                        className="my-3"
                        id="contactButton"
                        size="large"
                      >
                        Send Message
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const ContactWrapper = Form.create({ name: "contact_form" })(ContactPage);

export default ContactWrapper;
