import base64 from "base-64";
// If site is in production or localhost, also for testing change the port number to 3000 instead of 3312 to view in browser rather than download file independently.
const siteURL =
  window.location.hostname == "localhost"
    ? "http://localhost:3312/invoices/"
    : "https://jordenmcdonald.com/invoices/";

var AppController = {
  encodeData(data) {
    let encodeString = data;
    for (let i = 0; i < 10; i++) {
      encodeString = base64.encode(encodeString);
    }
    return encodeString;
  },
  decodeData(data) {
    let decodeString = data;
    for (let i = 0; i < 10; i++) {
      decodeString = base64.decode(decodeString);
    }
    return decodeString;
  },
  friendlyUrl(data) {
    let str = data;
    str = str.replace(/\s+/g, "-").toLowerCase();
    return str;
  },
  friendlyWebsite(data) {
    let str = data;
    str = str.replace(/(^\w+:|^)\/\//, "");
    return str;
  },
  removeHyphen(data) {
    let str = data;
    str = str.split("-").join(" ");
    return str;
  },
  addUnderscore(data) {
    let str = data;
    str = str.replace(/-/g, "_");

    return str;
  },
  getDay(data) {
    // Converts Unix time such as "1563606000" to Day of Week Ex.. Monday, Tuesday etc...
    var i = 0;
    var str = { list: [{ dt: data }] };

    var days = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    var dayNum = new Date(str.list[i].dt * 1000).getDay();
    var result = days[dayNum];

    return result;
  },
  getDate(data) {
    // Converts Unix time such as "1563606000" to Day of Month Ex.. 7/1, 7/2 etc...
    var i = 0;
    var str = { list: [{ dt: data }] };

    var dayMonth = new Date(str.list[i].dt * 1000).getMonth() + 1;
    var dayNum = new Date(str.list[i].dt * 1000).getDate();
    var result = dayMonth + "/" + dayNum;

    return result;
  },
  userData() {
    const getuserData = localStorage.getItem("userData");
    const userData = JSON.parse(getuserData);

    return userData;
  },
  converttoCelcius(temp) {
    let temperature = temp;
    let cTemp = (temperature - 32) * (5 / 9);

    return cTemp;
  },
  isAdmin() {
    let userData;
    if (localStorage.getItem("userData") !== null) {
      userData = JSON.parse(localStorage.getItem("userData"));
      if (userData.role_id === 3) {
        return userData;
      }
    }
  },
  downloadPDF() {
    return siteURL;
  },
  commaNumbers(data) {
    return data.toLocaleString();
  },

  formatDate(data) {
    const date = new Date(data);
    const formattedDate = `${(date.getMonth() + 1)
      .toString()
      .padStart(2, "0")}/${date.getDate().toString().padStart(2, "0")}/${date
      .getFullYear()
      .toString()
      .slice(2)}`;

    return formattedDate;
  },
};

export default AppController;
