import React, { useEffect, useState } from "react";

import AppController from "../../../controllers/AppController";

import WebPageService from "../../../services/WebService";

import WebProjectsSlide from "./WebProjectsSlide";
import WebProjectsSkeleton from "../Skeletons/WebProjectsSkeleton";
import ColorScheme from "./ColorScheme";
import Typography from "./Typography";

import { Link } from "react-router-dom";

const WebProjects = (props) => {
  const [projectData, fetchProjectInfo] = useState([]);
  const { theme } = props;

  const selectProjectInfo = async () => {
    let selectProjects = await WebPageService.SelectWebInfo({
      webId: props.match.params.projectid,
    });
    const splitWebTechnology = selectProjects[0].web_technology.split(",");
    const splitWebIcons = selectProjects[0].web_icons.split(",");
    const splitWebText = selectProjects[0].web_process.split("<br/>");

    // Clean array with split icons
    const selectProjectsArray = {
      ...selectProjects[0],
      web_technology: splitWebTechnology,
      web_icons: splitWebIcons,
      web_text: splitWebText,
    };

    // console.log("project data is222", splitWebText);

    fetchProjectInfo(selectProjectsArray);
  };

  useEffect(() => {
    // console.log('I got hit!');
    selectProjectInfo();
  }, []);

  useEffect(() => {
    // console.log("project data is", projectData);
  }, [projectData]);

  return (
    <div className="container-fluid">
      <div className="webprojectsContainer">
        <div className="projectsubContainer">
          <div className="mainprojectCover">
            <h1>
              {projectData.web_topic ? (
                `${projectData.web_topic} Website`
              ) : (
                <WebProjectsSkeleton render={"webheader"} />
              )}
            </h1>
          </div>
          <div className="container" id="webProjects">
            <nav aria-label="breadcrumb" className="mt-3">
              <ol
                className="breadcrumb"
                style={{ backgroundColor: "transparent", fontWeight: "bold" }}
              >
                <li className="breadcrumb-item">
                  <Link to="/">Home</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  {projectData.web_topic ? (
                    `Web Project (${projectData.web_topic})`
                  ) : (
                    <WebProjectsSkeleton render={"webheader"} />
                  )}
                </li>
              </ol>
            </nav>

            <div className="projectsContainer">
              <div className="row">
                <div className="col-lg-6 text-lg-left mt-lg-0 col-md-12 mt-md-3 text-md-center">
                  <div className="projectCover">
                    <h1>Project Details</h1>

                    <div className="projectDetails py-2">
                      <span className="projectInfo font-weight-bold">
                        Status:{" "}
                        {projectData.web_status ? (
                          <button className="btn btn-success">Online</button>
                        ) : (
                          <button className="btn btn-danger">Offline</button>
                        )}
                      </span>
                    </div>
                    <div
                      className="projectDetails py-2"
                      style={{ wordBreak: "break-all" }}
                    >
                      <div className="projectInfo font-weight-bold">
                        Website URL:{" "}
                        {projectData.web_url ? (
                          <span>
                            <a
                              href={projectData.web_url}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {projectData.web_url}
                            </a>
                          </span>
                        ) : (
                          <WebProjectsSkeleton render={"weburl"} />
                        )}
                      </div>
                    </div>
                    <div className="projectDetails py-2">
                      <span className="projectInfo font-weight-bold">
                        Github URL:{" "}
                        <span>
                          {projectData.github_status ? (
                            <a
                              href={projectData.github_url}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {AppController.friendlyWebsite(
                                projectData.github_url
                              )}
                            </a>
                          ) : (
                            <span className="badge badge-danger">
                              Not Available - Private Repo - (NDA Signed)
                            </span>
                          )}
                        </span>
                      </span>
                    </div>
                    <div className="projectDetails py-2">
                      <span className="projectInfo font-weight-bold">
                        Role:{" "}
                        <span style={{ color: "#089AED" }}>
                          UI/UX Designer, Full Stack Developer
                        </span>
                      </span>
                    </div>
                    <div className="projectDetails py-2">
                      <WebProjectsSlide
                        webTechnology={projectData.web_technology}
                        webIcons={projectData.web_icons}
                        theme={theme}
                      />
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 text-lg-left mt-lg-0 col-md-12 mt-md-3">
                  <h1>Process</h1>
                  <div
                    className="box-container shadow-sm"
                    data-aos="fade-down"
                    data-aos-once="true"
                    style={{
                      background: theme === "light" ? "#36576B" : "#1A1B1D",
                      padding: "20px",
                      borderRadius: "20px",
                      height: "354px",
                      overflowY: "scroll",
                    }}
                  >
                    {projectData.web_text
                      ? projectData.web_text.map((text, index) => (
                          <p
                            className={
                              theme === "light"
                                ? "p-2 m-0 text-black"
                                : "p-2 m-0 text-white"
                            }
                            key={index}
                            style={{
                              fontSize: "18px",
                              color: "#fff",
                            }}
                          >
                            {text}
                          </p>
                        ))
                      : null}
                  </div>
                  {/* <div
                    className="processInfo border"
                    style={{ height: "354px", overflowY: "scroll" }}
                  >

                  </div> */}
                </div>
              </div>
              {projectData.video_process ? (
                <div className="video-process">
                  <h1 className="py-3">Video Demo</h1>
                  <video controls style={{ width: "100%" }}>
                    <source src={projectData.video_process} type="video/mp4" />
                  </video>
                </div>
              ) : null}

              {/* Color Scheme */}
              <ColorScheme color={projectData.color_scheme} theme={theme} />
              {/* Typography */}
              <Typography
                typography={projectData.typography}
                typographyImage={projectData.typography_img}
                theme={theme}
              />
              {projectData.responsive_preview ? (
                <div>
                  <h1 className="py-3">Reponsive Preview</h1>
                  <div
                    className="responsiveCover"
                    style={{
                      backgroundSize: "cover",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <div
                      className="text-center"
                      data-aos="fade-down"
                      data-aos-once="true"
                      style={{ backgroundColor: "#fff", borderRadius: "20px" }}
                    >
                      <img
                        src={projectData.responsive_preview}
                        className="w-100"
                        style={{ borderRadius: "15px" }}
                      />
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WebProjects;
