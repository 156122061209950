import React from "react";

import AOS from "aos"; // Animate on scroll

import ColorSchemeSkeleton from "../Skeletons/ColorSchemeSkeleton";

const ColorScheme = ({ color, theme }) => {
  AOS.init();
  const splitColors = color ? color.split(",") : null;

  return (
    <div className="color-container">
      <h1 className="py-3">Color Theme</h1>
      <div
        className="box-container text-center shadow-sm"
        data-aos="fade-down"
        data-aos-once="true"
        style={{
          background: theme === "light" ? "#36576B" : "#1A1B1D",
          padding: "20px",
          borderRadius: "20px",
        }}
      >
        {splitColors ? (
          splitColors.map((colors, index) => (
            <div className="box-parent d-inline-block" key={index}>
              <p
                className="font-weight-bold m-0 py-2"
                style={{
                  fontSize: "20px",
                  color: theme === "light" ? "#000" : "#fff",
                }}
              >
                {colors}
              </p>
              <div
                className="box m-3"
                style={{
                  width: "150px",
                  height: "150px",
                  background: colors,
                  borderRadius: "100%",
                }}
              ></div>
            </div>
          ))
        ) : (
          <ColorSchemeSkeleton render={"color"} />
        )}
      </div>
    </div>
  );
};

export default ColorScheme;
