import React from "react";

const WebProjectsSlide = (props) => {
  const { webTechnology, webIcons, theme } = props;
  let result = {};

  webTechnology
    ? webTechnology.forEach((key, i) => (result[key] = webIcons[i]))
    : null;

  return (
    <div className="project-info-container">
      <span className="projectInfo font-weight-bold">Tools Used:</span>

      <div
        className="box-container shadow-sm"
        data-aos="fade-down"
        data-aos-once="true"
        style={{
          background: theme === "light" ? "#36576B" : "#1A1B1D",
          padding: "20px",
          borderRadius: "20px",
        }}
      >
        <div className="slideshow d-flex justify-content-center">
          <div
            className="item d-flex justify-content-center"
            style={{ flexFlow: "wrap" }}
          >
            {Object.entries(result).map((item, index) => {
              return (
                <h4
                  className="m-0 data-tip-bottom"
                  data-tip={item[0]}
                  key={index}
                >
                  <i
                    className={`${item[1]} p-3`}
                    style={{ fontSize: "1.7em" }}
                  />
                </h4>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default WebProjectsSlide;
