import React from "react";

const Typography = ({ typography, typographyImage, theme }) => {
  return (
    <div className="type-container">
      <h1 className="py-3">Font Family</h1>
      <div
        className="box-container shadow-sm"
        data-aos="fade-down"
        data-aos-once="true"
        style={{
          background: theme === "light" ? "#36576B" : "#1A1B1D",
          padding: "20px",
          borderRadius: "20px",
        }}
      >
        <h2
          style={{
            color: "#0094e9",
            fontWeight: "bold",
            textDecoration: "underline",
          }}
        >
          {typography}
        </h2>
        <div className="type-img">
          <img
            src={typographyImage}
            className="w-100"
            alt="Font Family Sizes/Styles"
          />
        </div>
      </div>
    </div>
  );
};

export default Typography;
