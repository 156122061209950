import serviceBase from "./serviceBase";

const userprofileService = {
  selectUser: (data) => serviceBase.post("/api/selectUser", data),
  updateprofileImage: (data) =>
    serviceBase.post("/api/updateprofileImage", data),
  usermetricQuestions: (data) =>
    serviceBase.post("/api/usermetricQuestions", data),
  createInvoice: (data) => serviceBase.post("/api/createInvoice", data),
  selectInvoices: (data) => serviceBase.post("/api/selectInvoices", data),
  selectInvoicesByID: (data) =>
    serviceBase.post("/api/selectInvoicesByID", data),
  selectInvoicesByFacility: (data) =>
    serviceBase.post("/api/selectInvoicesByFacility", data),
  deleteInvoices: (data) => serviceBase.post("/api/deleteInvoices", data),
  updateInvoices: (data) => serviceBase.post("/api/updateInvoices", data),
};

export default userprofileService;
