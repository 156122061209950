import React, { useEffect } from "react";

import { Link, useHistory } from "react-router-dom";

const PaymentSuccess = () => {
  const history = useHistory();
  useEffect(() => {
    // Parse the URL search parameters
    const params = new URLSearchParams(window.location.search);
    const token = params.get("token");

    // Use the token in your component logic
    if (!token) {
      history.push("/");
    }
  }, []);

  return (
    <div className="container mt-5">
      <div className="card">
        <div className="card-body text-center">
          <h2 className="pb-4">Payment Successful!</h2>
          <i
            className="fa fa-check-circle fa-8x"
            style={{ color: "green" }}
          ></i>
          <p className="pt-4">
            <strong>Your payment has been successfully processed.</strong>
          </p>
          <p>
            <strong>An email receipt has been sent to your inbox.</strong>
          </p>
          <div className="button-actions d-flex  justify-content-center mt-4">
            <Link className="btn btn-primary d-block w-25" to="/search-invoice">
              Pay Another Invoice
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentSuccess;
