import React from "react";

import Skeleton from "react-loading-skeleton";

const BlogPageSkeleton = (props) => {
  const { render } = props;

  const renderTemplates = () => {
    switch (render) {
      case "blogtopic":
        return <Skeleton width={100} height={20} count={1} />;
      case "profilepic":
        return <Skeleton width={"100%"} height={100} count={1} />;
      case "blogcontent":
        return <Skeleton width={"100%"} height={20} count={2} />;
      default:
        return null;
    }
  };

  return <React.Fragment>{renderTemplates()}</React.Fragment>;
};

export default BlogPageSkeleton;
