import React from "react";

import { Link } from "react-router-dom";

import AppController from "../../../controllers/AppController";

import DisplayWebProjectsSkeleton from "../Skeletons/DisplayWebProjectsSkeleton";

const DisplayWebProjects = (props) => {
  const { webData, visible } = props;

  return (
    <div className="web-projects-container">
      {webData
        ? webData.slice(0, visible).map((rows, index) => (
            <div className="webInfo mb-3" key={index}>
              <div
                className="backgroundImage"
                style={{
                  backgroundImage: `url("https://jmsiteimages.s3-us-west-1.amazonaws.com/${rows.web_thumbnail}")`,
                }}
              />

              <div className="background_overlay" />
              <div className="background_content">
                {rows.web_url ? (
                  <a
                    href={rows.web_url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div
                      className="liveCaption d-inline-block m-2 alert alert-danger"
                      style={{ backgroundColor: "#bf1901" }}
                    >
                      <p
                        id="liveText"
                        className="text-center m-0 font-weight-bold text-white"
                        style={{ letterSpacing: "1px" }}
                      >
                        <span>&#8226;</span> LIVE
                      </p>
                    </div>
                  </a>
                ) : (
                  <DisplayWebProjectsSkeleton render={"live"} />
                )}

                <div className="projectHeading text-center">
                  <h1 className="animated fadeInDown">
                    {rows.web_topic ? (
                      rows.web_topic
                    ) : (
                      <DisplayWebProjectsSkeleton render={"webtopic"} />
                    )}
                  </h1>
                </div>
                <div className="projectDetails mt-5">
                  <div className="buttonContainer text-center">
                    {rows.web_id ? (
                      <Link
                        to={`/webprojects/${
                          rows.web_id
                        }/${AppController.friendlyUrl(rows.web_topic)}`}
                        className="btn btn-outline-primary mb-5"
                      >
                        VIEW PROJECT
                      </Link>
                    ) : (
                      <DisplayWebProjectsSkeleton render={"viewproject"} />
                    )}
                  </div>
                </div>
              </div>
            </div>
          ))
        : null}
    </div>
  );
};

export default DisplayWebProjects;
