import React from "react";
import { Helmet } from "react-helmet";

import BusinessCard from "./BusinessCard";

const AboutPage = ({ theme }) => {
  return (
    <React.Fragment>
      <div className="fluid-container" style={{ background: "#18191B" }}>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Jorden McDonald About</title>
          <meta
            name="description"
            content="Jorden McDonald is a Front End Developer that loves to builds websites and web applications completely from the ground up. Learn more about my experiences with web development and how I can help with your business needs today!"
          />
          <link rel="canonical" href="https://www.jordenmcdonald.com/about" />
        </Helmet>
      </div>
      <div className="container" id="aboutPage">
        <div
          className="sub-hero-banner d-flex justify-content-center"
          style={{
            position: "relative",
          }}
        >
          <img
            src="images/about-hero-photo.jpg"
            alt="Jorden McDonald About Me Photo"
            style={{
              borderRadius: "100%",
              width: "200px",
              position: "relative",
              zIndex: "4",
              top: "30px",
            }}
          />
        </div>
        <div className="about-video-container">
          <div className="animated fadeInDown" id="description-about">
            <h1>About</h1>
            <p>
              Hello, my name is Jorden. I'm a front-end developer who loves to
              create and develop websites from the ground up. As a graduate from
              The Art Institutes, I focused on mainly honing my skills in the
              fundamental languages of the web. However, I also have experience
              with back-end technologies as well such as NodeJS.
            </p>
          </div>
          <div className="animated fadeInDown" id="description-background">
            <h2>Background</h2>
            <p>
              I worked as a junior front-end developer for one of the fastest
              growing corporate housing companies in the United States{" "}
              <a
                href="https://www.suiteamerica.com/company-info"
                target="_blank"
                rel="noopener noreferrer"
              >
                SuiteAmerica.{" "}
              </a>
              At the company, I worked with other developers on project driven
              deadlines for meeting important business client requirements. In
              the public sector, I helped build the{" "}
              <a
                href="https://myvaccinerecord.cdph.ca.gov/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Digital Vaccine Record
              </a>{" "}
              front-end for California residents that allows them to request a
              digital vaccine record of their COVID-19 card.
            </p>
            <p>
              While I'm not at work, every day I put in some time to improve my
              skills in web development as I am either learning a new
              technology, library, API or something entirely new about a tool
              I'm not familiar with.
            </p>
          </div>
          <div className="animated fadeInDown" id="description-goal">
            <h2>Goal</h2>
            <p>
              I strive to work with other enthusiastic and optimistic people and
              developers to develop a better understanding of teamwork as well
              as learning new technologies and tools that will give me more
              experience within my field.
            </p>
          </div>
        </div>
        {/* Tools */}
        {/* <Tools theme={theme} /> */}
        {/* Business Card */}
        <BusinessCard theme={theme} />
      </div>
    </React.Fragment>
  );
};

export default AboutPage;
