import React from "react";
import { Form, Input, Button, Icon, Row, Col, Checkbox } from "antd";
import Recaptcha from "react-google-recaptcha";

import loginService from "../../../services/LoginService";
import Base64 from "base-64";

class LoginPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userName: "",
      password: "",
      captchaVerify: false,
      errorMsg2: false,
      errorMsg3: false,
      capslockEnabled: false,
    };
  }

  captchaVerify = (value) => {
    const capVerify = value;
    if (capVerify !== "") {
      this.setState({
        captchaVerify: true,
      });
    }
  };

  onChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  // Check if caps lock is currently on or not
  onKeyDown(e) {
    if (e.shiftKey === true) {
      this.setState({ capslockEnabled: true });
    }
  }

  onKeyUp(e) {
    if (e.keyCode === 16) {
      this.setState({ capslockEnabled: false });
    }
  }

  showPassword = (rule, value, callback) => {
    const { form } = this.props;
    if (value && this.state.confirmDirty) {
      form.validateFields(["confirm"], { force: true });
    }
    callback();
  };

  onSubmit(e) {
    e.preventDefault();

    // console.log("captcha state", this.state.captchaVerify);

    this.props.form.validateFields(async (err, values) => {
      if (!err) {
        const data = {
          userName: values.username.toLowerCase(),
          password: values.password,
          passwordHash: Base64.encode(values.password),
        };

        const userData = await loginService.Auth(data);

        // Check if credentials are correct
        if (userData.length === 0) {
          this.setState({
            errorMsg2: true,
          });
        } else if (this.state.captchaVerify === false) {
          this.setState({
            errorMsg3: true,
          });
        } else {
          this.setState({
            errorMsg2: false,
            errorMsg3: false,
          });
          location.href =
            "/userprofile/" + userData[0].userID + "/" + userData[0].username;
          localStorage.setItem("userData", JSON.stringify(userData[0]));
        }
      }
    });
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <div className="container">
        <div className="loginformContainer" style={{ marginTop: "15%" }}>
          {/* Invalid Credentials */}
          {this.state.errorMsg2 === true ? (
            <p style={{ color: "red", textAlign: "center" }}>
              Invalid Credentials.
            </p>
          ) : null}
          {/* Recaptcha not filled out */}
          {this.state.errorMsg3 === true ? (
            <p style={{ color: "red", textAlign: "center" }}>
              Please verify you're not a robot.
            </p>
          ) : null}
          {/* Caps Lock Enabled */}
          {this.state.capslockEnabled === true ? (
            <div className="alert alert-info">Caps Lock Enabled</div>
          ) : null}
          <Form className="login-form" onSubmit={(e) => this.onSubmit(e)}>
            <Row type="flex" justify="center" align="middle">
              <Col md={8}>
                <Form.Item>
                  {getFieldDecorator("username", {
                    rules: [
                      { required: true, message: "Please enter your username" },
                    ],
                  })(
                    <Input
                      prefix={
                        <Icon
                          type="user"
                          style={{ color: "rgba(0,0,0,.25)" }}
                        ></Icon>
                      }
                      placeholder="Username"
                    />
                  )}
                </Form.Item>
              </Col>
            </Row>
            <Row type="flex" justify="center">
              <Col md={8}>
                <Form.Item>
                  {getFieldDecorator("password", {
                    rules: [
                      { required: true, message: "Please enter password" },
                      { validator: this.showPassword },
                    ],
                  })(
                    <Input.Password
                      prefix={
                        <Icon
                          type="lock"
                          style={{ color: "rgba(0,0,0,.25)" }}
                        ></Icon>
                      }
                      placeholder="Password"
                    />
                  )}
                </Form.Item>
              </Col>
            </Row>
            {/* <Row type="flex" justify="center">
              <Button
                type="link"
                id="forgotnext"
                onClick={() => this.props.history.push("/forgot-password")}
              >
                Forgot Password
              </Button>
            </Row> */}
            <Row gutter={8} style={{ textAlign: "center" }}>
              <Button type="primary" htmlType="submit" id="loginButton">
                Log in
              </Button>
            </Row>
            {/* <Row type="flex" justify="center">
              <Button
                type="link"
                id="registernext"
                onClick={() => this.props.history.push("/register")}
              >
                Register
              </Button>
            </Row> */}
            <div className="recaptcha">
              <Row type="flex" justify="center">
                <Recaptcha
                  style={{
                    marginTop: "20px",
                  }}
                  sitekey={process.env.RECAPTCHA_API_KEY}
                  onChange={this.captchaVerify}
                />
              </Row>
            </div>
          </Form>
        </div>
      </div>
    );
  }
}

const LoginWrapper = Form.create({ name: "login_form" })(LoginPage);

export default LoginWrapper;
