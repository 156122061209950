import React, {
  useEffect,
  useState,
  lazy,
  createContext,
  Suspense,
} from "react";

import { Route, Switch, withRouter } from "react-router-dom";
import LoadingBar from "react-top-loading-bar";

import HeaderIcons from "./common/HeaderIcons";
import SideBar from "./common/SideBar";
import Header from "./common/Header";
import Footer from "./common/Footer";

const LoginPage = lazy(() => import("./components/LoginPage/LoginPage"));
const AboutPage = lazy(() => import("./components/AboutPage/AboutPage"));
const ContactPage = lazy(() => import("./components/ContactPage/ContactPage"));
const InvoicePage = lazy(() => import("./components/InvoicePage/InvoicePage"));
const PaymentSuccess = lazy(() =>
  import("./components/InvoicePage/PaymentSuccess")
);
const InvoiceDetails = lazy(() =>
  import("./components/InvoicePage/InvoiceDetails")
);
const NoMatch = lazy(() => import("./components/NoMatchPage/NoMatch"));
const UserProfilePage = lazy(() =>
  import("./components/UserProfilePage/UserProfilePage")
);
const HomePage = lazy(() => import("./components/HomePage/HomePage"));
const WebProjects = lazy(() =>
  import("./components/WebProjectsPage/WebProjects")
);
const BlogPage = lazy(() => import("./components/BlogPage/BlogPage"));
const Pricing = lazy(() => import("./components/PricingPage/PricingPage"));

export const ThemeContext = createContext(null);

const Routes = ({ history }) => {
  const [theme, setTheme] = useState(null);
  const [progress, setProgress] = useState(0);
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const toggleTheme = (e) => {
    if (e === true) {
      localStorage.setItem("theme", "dark");
      setTheme("dark");
    }

    if (e === false) {
      localStorage.setItem("theme", "light");
      setTheme("light");
    }
  };

  useEffect(() => {
    if (
      localStorage.getItem("theme") === null ||
      localStorage.getItem("theme") === "dark"
    ) {
      localStorage.setItem("theme", "dark");
      setTheme("dark");
    } else if (localStorage.getItem("theme") === "light") {
      localStorage.setItem("theme", "light");
      setTheme("light");
    }
  }, [theme]);

  useEffect(() => {
    const unlisten = history.listen(() => {
      // Close the sidebar on route change
      setSidebarOpen(false);
      window.scrollTo(0, 0);
      setProgress(100);
    });

    return () => {
      unlisten();
    };
  }, [history]);

  return (
    <ThemeContext.Provider value={{ theme, toggleTheme }}>
      <div className="App" id={theme}>
        <Suspense fallback={<div style={{ color: "#fff" }}>Loading...</div>}>
          <LoadingBar
            color="#007BFF"
            progress={progress}
            height={3}
            onLoaderFinished={() => setProgress(0)}
          />
          <SideBar theme={theme} toggleTheme={toggleTheme} history={history} />
          <HeaderIcons theme={theme} />
          <Header theme={theme} toggleTheme={toggleTheme} />
          <Switch>
            <Route
              exact
              path="/"
              render={(props) => <HomePage {...props} theme={theme} />}
            />
            <Route exact path="/login" component={LoginPage} />
            <Route
              exact
              path="/about"
              render={(props) => <AboutPage {...props} theme={theme} />}
            />
            <Route
              exact
              path="/pricing"
              render={(props) => <Pricing {...props} theme={theme} />}
            />
            <Route
              exact
              path="/blog/:blogid/:blogtopic"
              render={(props) => <BlogPage {...props} theme={theme} />}
            />
            <Route
              exact
              path="/contact"
              render={(props) => <ContactPage {...props} theme={theme} />}
            />
            <Route
              exact
              path="/webprojects/:projectid/:projectname"
              render={(props) => <WebProjects {...props} theme={theme} />}
            />
            <Route
              exact
              path="/search-invoice"
              render={(props) => <InvoicePage {...props} theme={theme} />}
            />
            <Route
              exact
              path="/payment-success"
              render={(props) => <PaymentSuccess {...props} theme={theme} />}
            />
            <Route
              exact
              path="/invoice-details/:invoicenumber/:lastname"
              render={(props) => <InvoiceDetails {...props} theme={theme} />}
            />
            <Route
              exact
              path="/userprofile/:userid/:username"
              component={UserProfilePage}
            />
            <Route render={(props) => <NoMatch {...props} theme={theme} />} />
          </Switch>
          <Footer theme={theme} />
        </Suspense>
      </div>
    </ThemeContext.Provider>
  );
};

export default withRouter(Routes);
