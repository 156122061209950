import serviceBase from './ServiceBase';

const blogService = {
    insertblogTopics: (data) => serviceBase.post('/api/insertblogTopics', data),
    SelectBlogTopics: (data) => serviceBase.post('/api/selectblogTopics', data),
    blogpageTopic: (data) => serviceBase.post('/api/blogpageTopic', data),
    adminupdateTopic: (data) => serviceBase.post('/api/adminupdateTopic', data),
    insertblogReply: (data) => serviceBase.post('/api/insertblogReply', data),
    selectblogReplies: (data) => serviceBase.post('/api/selectblogReplies', data),
    deleteblogReply: (data) => serviceBase.post('/api/deleteblogReply', data),
    editblogReply: (data) => serviceBase.post('/api/editblogReply', data),
}

export default blogService;